<template>
  <el-dialog
    append-to-body
    width="560px"
    title="库存单位转换"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    >
    <el-descriptions title="" :column="2">
      <el-descriptions-item label="标准名称">{{curRow.norm_name}}</el-descriptions-item>
      <el-descriptions-item label="单位">{{curRow.unit_name}}</el-descriptions-item>
      <el-descriptions-item label="规格">
        <span v-if="curRow.spec_names.length > 1">存在多个商品规格</span>
        <span v-else>{{curRow.spec_names[0]}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="现有库存">
        <!-- 现有库存 = (入库数量 - 已出库 - 已处理) -->
          <span>{{(Number(curRow.quantity) - Number(curRow.out_quantity) - Number(curRow.env_quantity)).toFixed(5) * 1}} {{curRow.unit_name}}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-form
      class="staff-from"
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='100px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item v-if="unit_type == 3">
        <el-radio-group v-model="form.data.type" @change="changeUnit">
          <el-radio :label="1">基础单位</el-radio>
          <el-radio :label="2">非基础单位</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 基础单位转换 -->
      <template v-if="form.data.type == 1">
        <el-row>
          <el-col :span="24">
            <el-form-item label="需转换库存" prop="num">
              <el-input
                v-model.trim="form.data.num"
                placeholder="请输入"
                style="width:180px;margin-right:10px;"
                clearable
                @input="countNum">
              </el-input>
              <el-select v-model="form.data.spec_id" @change="countNum">
                <el-option v-for="item in unitArr" :key="item.id" :label="item.curName" :value="item.id">
                  <span style="float: left">{{ item.curName }}</span>
                  <span class="spec_name">{{ item.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="被转换库存">
          <el-input
            v-model.trim="form.data.total"
            style="width:180px;"
            :disabled="true">
            <span slot="suffix">{{curRow.unit_name}}</span>
          </el-input>
        </el-form-item>
      </template>
      <!-- 非基础单位转换 -->
      <template v-if="form.data.type == 2">
        <el-form-item label="需转换">
          <el-input
            v-model.trim="form.data.total"
            style="width:180px;"
            :disabled="true">
            <span slot="suffix">{{unitObj[0].unit_name}}</span>
          </el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="被转换库存" prop="num">
              <el-input
                v-model.trim="form.data.num"
                 placeholder="请输入"
                style="width:180px;margin-right:10px;"
                clearable
                @input="countNum2">
              </el-input>
              <span style="margin-right:10px;">{{curRow.unit_name}}</span>
              <span>1{{curRow.unit_name}} = {{unitObj[0].basic_scale}}{{unitObj[0].unit_name }} </span>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </el-form>
    
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click='confirm' :loading="loading">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'StoreStockExcise',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        curRow: {
          spec_names: [],
        },
        form: {
          loading: false,
          data: {
            id: '',
            num: '',
            spec_id: '',
            type: 1,
          },
          rules: {
            num: [{ required: true, message:'请输入', trigger: 'blur'}],
          }
        },
        unitArr: [],// 基础单位转非基础单位时用
        unitObj: [{
          basic_scale: '',
          good_id: '',
          id: '',
          is_basic: 0,
          unit_name: '',
        }],// 非基础单位转基础单位时用
        unit_type: 1,
       
      }
    },
    methods: {
      getDetail(row) {
        this.curRow = row;
        this.getChange();
      },
      // 获取转换单位
      getChange() {
        this.$http.get('/admin/inventory/getChange',{params: {ids: this.curRow.ids}}).then(res => {
          if(res.code == 1) {
            if(res.data.list.length > 0) {
              let arr = res.data.list.filter(v => v.is_basic != 1)
              this.unitArr = arr.map(item => {
                return {
                  ...item,
                  curName: `${item.unit_name }（${item.basic_scale}${this.curRow.unit_name}）`
                }
              })
              this.unitObj = res.data.list.filter(v => v.is_basic == 1); // 过滤出基础单位规格
            }
            this.unit_type = res.data.unit_type; //单位类型 1:基础单位 2:转换单位 3:两种都是
            this.form.data.type = res.data.unit_type == 2 ? 2 : 1
          }
        })
      },
      reset(done) {
        this.isChange = false;
        this.form.data.total = '';
        this.form.data.spec_id = '';
        this.$refs.elFormDom.resetFields()
        this.$emit('refresh')
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      changeUnit() {
        this.form.data.total = '';
        this.form.data.spec_id = '';
        this.form.data.num = '';
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              ids: this.curRow.ids,
              spec_id: this.form.data.type == 1 ? this.form.data.spec_id : this.unitObj[0].id, //规格id
              num: this.form.data.num, // 数量
            };
            this.loading = true;
            this.$http.post('/admin/inventory/change', _params).then(res => {
              if(res.code === 1) {
                this.$message.success("操作成功！")
                this.toggle(false)
              }
            }).finally(() => {
              this.loading = false;
            })
          }
        })
      },
       // 基础单位转换计算总数量
      countNum() {
        const reg = /^[\d]+$/;
        if (this.form.data.num && !reg.test(this.form.data.num)) {
          this.form.data.num = this.form.data.num.toString().replace(/[^0-9.]/g, '');
        }
        if(!!this.form.data.spec_id) {
          let arr = this.unitArr.filter(v => v.id == this.form.data.spec_id)
          let _total = Math.round(parseFloat(this.form.data.num * arr[0].basic_scale)*100000)/100000; // 计算总数
          // <!-- 现有库存 = (入库数量 - 已出库 - 已处理) -->
          let _inventory = (Number(this.curRow.quantity) - Number(this.curRow.out_quantity) - Number(this.curRow.env_quantity)).toFixed(2) * 1; // 计算现有库存
          if(_total > _inventory) {
            this.$message.warning("数值不能大于现有库存")
            this.form.data.total = '';
            return
          } else {
            this.form.data.total = _total;
          }
        }
      },
      // 非基础单位转换计算总数量
      countNum2() {
        const reg = /^[\d]+$/;
        if (this.form.data.num && !reg.test(this.form.data.num)) {
          this.form.data.num = this.form.data.num.toString().replace(/[^0-9.]/g, '');
        }
        let _total = Math.round(parseFloat(this.form.data.num * this.unitObj[0].basic_scale)*100000)/100000; // 计算总数
        this.form.data.total = _total;
      }
    }
  }
</script>
<style scoped>
.spec_name {
  float: right;
  margin-left:20px;
  color: #8492a6;
  font-size: 13px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
