<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">询价中({{ table.total_1 }})</el-radio-button>
      <el-radio-button :label="2">询价结束({{ table.total_2 }})</el-radio-button>
      <el-radio-button :label="3">询价完成({{ table.total_3 }})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="标准名称" prop="norm_name">
          <el-input
            size="mini"
            v-model.trim="table.params.norm_name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplier_id">
          <el-select v-model.trim="table.params.supplier_id" filterable id="bind_id" ref="elementSelect" @input.native="trimInput" clearable placeholder="请输入" @focus="getSupplierSel" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="截止日期" prop="end_time">
          <el-date-picker
            v-model="table.params.end_time"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
             @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发起日期" prop="create_time">
          <el-date-picker
            v-model="table.params.create_time"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
             @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">发起询价</el-button>
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="handleExport"
          >导出</el-button>
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="handleImport"
          >导入报价</el-button>
        <template v-if="table.params.status == 1">
          <el-button
            :size="subUnitSize"
            type="primary"
            plain
            @click="handleFinish"
            >批量结束</el-button>
          <el-button
            :size="subUnitSize"
            type="primary"
            plain
            @click="handleDateEdit"
            >批量修改截止日期</el-button>
        </template>
      </div>
    </div>
    <VTable
      has-pagionation
      :field="table.params.status == 3 ? field2 : field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:norm_name="{row}">
        <div style="display:flex;align-items:center;">
          <el-image
            v-if="row.image"
            style="width: 40px; height: 40px;margin-right:10px;"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
          <el-image
            v-else
            style="width: 40px; height: 40px;margin-right:10px;"
            :src="require('@/assets/img/logo-icon.jpeg')"
            fit="cover"
            :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
          <span>{{row.norm_name}}</span>
        </div>
      </template>
      <!-- 分类 -->
      <template v-slot:cate_name="{row}">
        <span>{{row.cate_name}}</span>
        <span v-if="!!row.cate_tow_name"> / {{row.cate_tow_name}}</span>
      </template>
      <template v-slot:details="{row}">
        <div v-for="(item,index) in row.details" :key="index">
          <span>¥ {{item.amount}}</span>
          <span>（{{item.supplier_name}}）</span>
        </div>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" v-if="table.params.status == 1 || table.params.status == 3" @click="showDetail(row,2)">查看</el-button>
        <el-button type="text" v-if="table.params.status == 1" @click="handleCancel(row)">结束</el-button>
        <el-button v-if="table.params.status == 2" type="text" @click="showDetail(row,1)">选择供应商</el-button>
      </template>
    </VTable>

    <!-- 导入报价 -->
    <el-dialog title="导入报价" :visible.sync="dialogVisible" width="460px" :before-close="handleClose">
      <el-form :model="form.data" ref="elFormDom2" :rules="form.rules" label-position="top">
        <el-form-item label="供应商" prop="supplier_id">
          <el-select v-model="form.data.supplier_id" filterable>
            <el-option
              v-for="item in supplierArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件" prop="file">
          <el-upload
            class="upload-demo"
            :limit="1"
            :headers="{token:token}"
            :file-list="fileList"
            :on-remove="removeFile"
            :multiple="false"
            action="/api/common/qiniu/uploadFile"
            :on-success="handleAvatarSuccess"
            accept=".xls,.xlsx">
            <el-button icon="el-icon-upload" slot="trigger" size="small" type="primary">选取文件</el-button>
            <a :href="sampleFile">
              <el-button type="text" class="downBtn" style="margin-left:30px;">下载样表</el-button>
            </a>
          </el-upload>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" :loading="form.loading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      title="导入报价反馈信息"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
      >
      <div style="font-weight: bold;">导入成功：{{templateDialog.result}} 条</div>
      <div v-if="templateDialog.errors.length > 0" class="errorBox">
        <p v-for="(item, index) in templateDialog.errors" :key="index">{{index +1}}、{{item}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="errorExp">下载反馈信息</el-button>
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 批量修改截止日期 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleForm"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-alert
        :title="`提示：选中商品${multipleSelection.length}个`"
        type="warning"
        style="margin-bottom:20px;"
        :closable="false">
      </el-alert>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom1'
        label-position='right'
        label-width="100px"
        :model='form1.data'
        :rules='form1.rules'
        :size="subUnitSize"
      >
        <el-form-item label="截止日期" prop="end_time">
          <el-date-picker
            v-model="form1.data.end_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="curPickerOptions"
            >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form1.loading" :disabled="form1.loading" @click="submitForm">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 采购单详情 -->
    <detail ref="detail" @refresh="getTable"></detail>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Details.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: 'EnquiryIndex',
  components: {
    Edit,
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      loading: false,
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "norm_name", label: "标准名称", width:'190', hidden: false },
        { name: "cate_name", label: "分类", width:'190', hidden: false },
        { name: "unit_name", label: "单位", width:'80', hidden: false},
        { name: "demand", label: "询价要求", showTooltip: true, hidden: false},
        { name: "supplier_count", label: "参加报价数", hidden: false},
        { name: "complete_num", label: "已报价数", hidden: false},
        { name: "end_time", label: "截止日期", hidden: false},
        { name: "create_time", label: "创建时间",  width:'160', hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "norm_name", label: "标准名称", width:'190', hidden: false },
        { name: "cate_name", label: "分类", width:'180', hidden: false },
        { name: "unit_name", label: "单位", width:'80', hidden: false},
        { name: "demand", label: "询价要求", showTooltip: true, hidden: false},
        { name: "supplier_count", label: "参加报价数", hidden: false},
        { name: "complete_num", label: "已报价数", hidden: false},
        { name: "end_time", label: "截止日期", width:'120', hidden: false},
        { name: "details", label: "选择报价",  width:'200', hidden: false},
        { name: "complete_time", label: "完成时间",  width:'140', hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      multipleSelection:[],
      table: {
        loading: false,
        params: {
          norm_name: '',
          status: 1, //状态 1:询价中 2:询价到期 3:询价完成
          order_cate_arr: [], //下单分类
          order_cate_id: '',
          order_cate_tow: '',
          supplier_id: '', //供应商
          end_time: '', // 截止日期
          create_time: '', // 发起日期
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total_1: 0,
        total_2: 0,
        total_3: 0,
      },
      sampleFile: '', // 样表
      id_arr: '',
      supplierArr: [], // 供应商列表/采购员
      orderCateArr: [], // 下单分类

      form: {
        loading: false,
        data: {
          supplier_id: '',
          file:'',
        },
        rules: {
          supplier_id: [{ required: true, message: '请选择供应商', trigger: ['change','blur'] }],
          file: [{ required: true, message: '请上传文件', trigger: ['change','blur'] }]
        },
      },
      fileList: [],
      // 导入后提示
      dialogVisible: false,
      errorDialogVisible: false,  // 导入异常弹窗
      templateDialog: {
        result: 0,
        errors: [],
      },
      curfile: {},
      dialogVisibleForm: false,
      form1: {
        loading: false,
        data: {
          end_time: '',
        },
        rules: {
          end_time: [{ required: true, message: '请选择截止日期', trigger: 'change' }]
        },
      },
      curPickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 禁用历史日期
        },
      }
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {
        ...this.table.params,
      }
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http.get('/admin/offer/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
          this.table.total_3 = res.data.total_3;
          this.sampleFile = res.data.file;// 样表
        }
      })
    },
    // 获取供应商 (启用中的供应商)
    getSupplier() {
      this.$http.get('/admin/supplier/list', {params:{status:1, page:1, count:10000}}).then(res => {
        if(res.code === 1) {
          this.supplierArr = res.data.list;
        }
      })
    },
    // 重置查询条件
    reset() {
      this.table.params = {
        status: this.table.params.status,
        order_cate_arr: [],
        order_cate_id: '', // 一级分类
        order_cate_tow: '',// 二级分类
        supplier_id: '', //供应商
        end_time: '', // 截止日期
        create_time: '', // 发起日期
      };
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.getTable();
    },
    // 编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    // 详情,选择供应商
    showDetail(row,type) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      dom.getDetail(row,type)
      dom = null;
    },
    // 修改供应商
    showSupplier(row) {
      let dom = this.$refs.supplierEdit;
      dom.toggle(true);
      dom.getDetail(row, 1); // 传1单条修改供应商/采购员
      dom = null;
    },
    // 结束
    handleCancel(row) {
      this.setConfirm(
        `确认结束询价吗？`,
        {  id: row.id },
        "/admin/offer/finish"
      );
    },
    // 批量选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.id_arr = this.multipleSelection.map(v => {
        return v.id
      }).join(",")
    },
    // 导出
    handleExport() {
      if(this.multipleSelection.length > 0) {
        this.exportFun({ids:this.id_arr,...this.table.params},'/admin/offer/export')
      } else {
        this.$confirm('您未勾选数据，需要导出列表全部数据吗?', '', {
          confirmButtonText: '确定',
          showCancelButton: false,
          center: true
        }).then(() => {
          this.exportFun({ids:this.id_arr,...this.table.params},'/admin/offer/export')
        }).catch(() => {});
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.fileList = [];
      this.form.data.file = '';
      this.form.data.supplier_id = '';
      this.$refs.elFormDom2.resetFields();
    },
    // 下载样表
    handleDownload() {
      exportData({},'/admin/goods/download')
    },
    handleAvatarSuccess(res, file) {
      this.curfile = file.raw;
      this.form.data.file = res.data.all_path_url;
      this.$refs.elFormDom2.clearValidate();
    },
    // 导入
    uploadSubmit() {
      this.$refs.elFormDom2.validate(valid => {
        if(valid) {
          console.log(this.form.data.file, "012");
          if(!!this.form.data.file) {
            this.uploadRequer();
          } else {
            this.$message.warning('请选择要导入的文件')
          }
        }
      })
    },
    // 导入请求
    uploadRequer() {
      let formData = new FormData();
      formData.append('file', this.curfile);
      formData.append('supplier_id', this.form.data.supplier_id);
      this.form.loading = true;
      this.$http.post('/admin/offer/import', {formData, type:'upload'}).then(res => {
        if(res.code == 1) {
          this.errorDialogVisible = true;
          this.templateDialog.errors = res.data.errors;
          this.templateDialog.result = res.data.success;
        }
      }).finally(() => {
        this.form.loading = false;
        this.dialogVisible = false;
      })
    },
    handleImport() {
      this.dialogVisible = true;
      this.fileList = [];
      this.form.data.supplier_id = '';
      this.form.data.file = '';
      this.getSupplier();
    },
    errorExp() {
      const text = this.templateDialog.errors;
      const blob = new Blob([text], {type:'text/plain'});
      // 创建a标签元素
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob)
      link.download ='file.txt';
      // 添加到页面上进行点击下装
      document.body.appendChild(link);
      link.click();
      // 移除临时生成的a标签无素
      document.body.removeChild(link);
    },
    removeFile() {
      this.fileList = [];
    },
    errorClose() {
      this.errorDialogVisible = false
      this.getTable();
    },
    // 批量结束
    handleFinish() {
      if(this.multipleSelection.length > 0) {
        this.$confirm(`选中商品${this.multipleSelection.length}个，确认结束吗？`, '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = this.multipleSelection.map(v => v.id).join(",");
          this.$http.post('/admin/offer/finish',{id: ids }).then(res => {
            if(res.code == 1) {
              this.$message.success("操作成功")
              this.getTable();
            }
          })
        })
      } else {
        this.$message.warning("请至少选择一条数据")
      }
    },
    handleDateEdit() {
      if(this.multipleSelection.length > 0) {
        this.dialogVisibleForm = true;
      } else {
        this.$message.warning("请至少选择一条数据")
      }
    },
    submitForm() {
      this.$refs.elFormDom1.validate(valid => {
        if(valid) {
          let ids = this.multipleSelection.map(v => v.id).join(",")
          let _params = {
            id: ids, //id 多个逗号隔开
            end_time: this.form1.data.end_time //截止时间
          }
          this.$http.post('/admin/offer/patchUpdate',_params).then(res => {
            if(res.code == 1) {
              this.dialogVisibleForm = false;
              this.form1.data.end_time = '';
              this.$refs.elFormDom1.resetFields()
              this.$message.success("操作成功")
              this.getTable();
            }
          })
        }
      })
    }

  }
}
</script>

<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  color: #2cb167;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.main-view {
  overflow: auto;
}
.main-view::-webkit-scrollbar {
  width:0px;
}
.main-view::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 0px;
}

.downBtn:hover {
  text-decoration:underline;
}
.btitle::before {
  content: "*";
  color: #F56C6C;
  margin-right: 4px;
}
</style>
