<template>
  <el-dialog
    append-to-body
    width="1000px"
    title="修改数量"
    :visible.sync="visible"
    :before-close="handleReturn"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
    <el-table
        :data="detailsData"
        style="width: 100%"
        max-height="500px"
        border
        >
        <el-table-column label="商品" prop="good_name" width="240">
          <template slot-scope="{row}">
            <div class="good">
              <el-image
                v-if="!!row.good_picture"
                style="width: 40px; height: 40px"
                :src="row.good_picture"
                fit="cover"
                :preview-src-list="[row.good_picture]"></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
              <div class="good-info">
                <div style="color:#595959;margin-bottom:6px">{{row.good_name}}</div>
                <div>规格：{{row.spec_name}}</div>
                <div>商品ID：<span style="color:#595959">{{row.good_id}}</span></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="售价">
          <template slot-scope="{row,$index}">
            <!-- open_price 订单是否允许改价 1:是 2:否 -->
            <el-popover
              v-if="open_price == 1"
              :ref="`popoverDom0+${$index}`"
              placement="bottom"
              width="300"
              trigger="click">
              <el-form
                class="staff-from"
                :model='form'
              >
                <el-form-item label="修改售价" prop="price">
                  <el-input v-model="form.price" autosize :placeholder="row.price" size="small"
                  @input="form.price=form.price.match(/\d+(\.\d{0,2})?/) ? form.price.match(/\d+(\.\d{0,2})?/)[0] : ''">
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </el-form>
              <el-row type="flex" justify="center">
                <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handlePrice(row,$index)">确定</el-button>
              </el-row>
              <el-button slot="reference" type="text"  style="color:#409eff;width:98px;text-align: left;">
                <span>¥{{row.price}}/{{row.spec_unit_name}}</span>
                <i class="el-icon-edit" style="margin-left:10px;"></i>
              </el-button>
            </el-popover>
            <span v-else>¥{{row.price}}/{{row.spec_unit_name}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          width="100">
          <template slot-scope="{row}">
            × <span style="font-weight: bold; font-size: 16px;">{{row.quantity}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          label="金额">
          <template slot-scope="{row}">
            <span>{{row.amount}}元</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="120"
          :show-overflow-tooltip="true">
          <template slot-scope="{row,$index}">
            <!-- amount金额为0的则是全部退货了不展示修改备注和修改数量功能 -->
            <template v-if="row.amount != 0">
              <el-popover
                :ref="`popoverDom+${$index}`"
                placement="bottom"
                width="300"
                trigger="click">
                <el-form
                  class="staff-from"
                  :model='form'
                >
                  <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" autosize placeholder="请输入（限50字）" size="small" type="textarea" maxlength="50"></el-input>
                  </el-form-item>
                </el-form>
                <el-row type="flex" justify="center">
                  <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleRemark(row,$index)">确定</el-button>
                </el-row>
                <el-button slot="reference" type="text"  style="color:#409eff;width:98px;text-align: left;">
                  <span v-if="!!row.remark" style="width:98px;display: inline-block;overflow: hidden;">{{row.remark}}</span>
                  <i v-else class="el-icon-edit-outline"></i>
                </el-button>
              </el-popover>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="num"
          label="修改数量"
          width="160px">
          <template slot-scope="{row}">
            <!-- amount金额为0的则是全部退货了不展示修改备注和修改数量功能 -->
            <template v-if="row.amount != 0">
              <el-input
                size="small"
                v-model.trim="row.num"
                placeholder="实际数量"
                @input="numREG_EXP(row)"
                class="numWrapInput"
              >
                <el-button slot="append"  type="primary" icon="el-icon-check" circle @click="handleNum(row)"></el-button>
              </el-input>
            </template>
          </template>
        </el-table-column>
      </el-table>
  </el-dialog>
</template>

<script>
  export default {
    name: 'orderDetailNum',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        detailsData:[],
        currOrderId: '', // 订单id
        open_price: '', // 订单是否允许改价 1:是 2:否
        form: {
          remark: '',
          price: '',
        },
      }
    },
    methods: {
      getDetail(row) {
        this.currOrderId = row.id; // 订单id
        this.open_price = row.open_price; // 订单是否允许改价 1:是 2:否
        this.getList(row.id)
      },
      getList(orderId) {
        // 订单详情
        this.$http.post('/admin/order/detail', { id: orderId}).then(res => {
          if(res.code == 1) {
            if(!!res.data.details) {
              if(res.data.details.length > 0) {
                this.detailsData = res.data.details.map(v => {
                  return {
                    num: v.quantity,
                    ...v
                  }
                })
              }
            }else {
              this.detailsData = [];
            }
          }
        })
      },
      reset(done) {
        this.form.price = '';
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      },
      handleNum(row) {
        let _params = {
          id: this.currOrderId,  // 订单id
          detail_id: row.id, // 商品id
          quantity: row.num, // 数量
        }
        this.$http.post('/admin/order/updateQuantity', _params).then(res => {
          if(res.code == 1) {
            this.$message.success("操作成功！")
            this.getList(this.currOrderId); 
          }
        })
      },
      numREG_EXP(row) {
        const reg = /^[\d]+$/;
        // 任何单位的数量都可以输入5位小数
        if (row.num && !reg.test(row.num)) {
          row.num = row.num=row.num.match(/\d+(\.\d{0,5})?/) ? row.num.match(/\d+(\.\d{0,5})?/)[0] : '';
        }
      },
      // 修改备注
      handleRemark(row,index) {
        if(!this.form.remark) {
          this.$message.warning("请输入备注")
          return
        }
        let _params = {
          id: this.currOrderId,
          detail_id: row.id,
          remark: this.form.remark,
        }
        this.$http.post('/admin/order/updateRemark', _params).then(res => {
          if(res.code == 1) {
            this.form.remark = '';
            this.$message.success('操作成功！')
            this.$refs[`popoverDom+${index}`].doClose()
            this.getList(this.currOrderId); 
          }
        })
      },
      // 修改售价
      handlePrice(row,index) {
        if(!this.form.price) {
          this.$message.warning("请输入价格")
          return
        }
        let _params = {
          id: this.currOrderId,
          detail_id: row.id,
          amount: this.form.price,
        }
        this.$http.post('/admin/order/updatePrice', _params).then(res => {
          if(res.code == 1) {
            this.form.price = '';
            this.$message.success('操作成功！')
            this.$refs[`popoverDom0+${index}`].doClose()
            this.getList(this.currOrderId); 
          }
        })
      }
    }
  }
</script>
<style scoped>
.numWrapInput ::v-deep .el-input-group__append {
  color: #2cb167 !important;
}
.good {
  display: flex;
  align-items: center;
}
.good-info {
  margin-left: 15px;
  color: #898989;
}
</style>