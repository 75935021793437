<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="商品名称" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="city" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.city"
            :options="areaArr"
            :props="{ checkStrictly: false,emitPath:false, value:'id', label:'name'}"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="下单日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions"
            id="date" @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配送日期" prop="distr">
          <el-date-picker
            v-model="table.params.distr"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="selpickerOptions"
            :clearable="false"
            id="date" @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display:flex;align-items: center;">
        <el-button :size="subUnitSize" type="primary" plain @click="exportData('/admin/stat/export')" icon="el-icon-download" >全部导出</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="mergeexportData('/admin/stat/sellExport')" icon="el-icon-download" >导出进销明细</el-button>
        <div style="color:#f56c6c;font-size: 14px;font-weight: bold;margin-left:20px;">
          汇总：销售额 ¥{{table.total_amount}}，采购成本 ¥{{table.total_delivery_money}}，毛利 ¥{{table.total_gross}}，毛利率 {{table.total_rate }} %
        </div>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      :showIndex="true"
      :tableRowClassName="tableRowClassNameFun"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:goods_name="{row}">
        <div>{{row.goods_name}}</div>
        <div>{{row.code}}</div>
      </template>
      <!-- 累计毛利 = 累计销售金额-累计采购金额 -->
      <template v-slot:total_profit="{row}">
        <span :class="(Number(row.total_amount) - Number(row.delivery_money)).toFixed(2) < 0 ? 'redColor' : ''" >{{(Number(row.total_amount) - Number(row.delivery_money)).toFixed(2)}}</span>
      </template>
      <!-- 平均毛利 -->
       <template v-slot:avg_profit="{row}">
        <span :class="row.avg_profit < 0 ? 'redColor' : ''">{{row.avg_profit}}</span>
      </template>
      <!-- 评价毛利率 -->
      <template v-slot:avg_profit_rate="{row}">
        <span>{{row.avg_profit_rate}}%</span>
      </template>
     
      <template v-slot:quantity="{row}">
        <span>{{row.quantity}}</span>
      </template>
      <template v-slot:loss_num="{row}">
        <span>{{row.loss_num || 0}}</span>
      </template>
      <template v-slot:loss_amount="{row}">
        <span>{{row.loss_amount || 0}}</span>
      </template>
    </VTable>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "goods_name", label: "商品名称", width:"180", hidden: false },
        { name: "cate_name", label: "分类", hidden: false},
        { name: "name", label: "规格", width: "120", hidden: false},
        { name: "unit_name", label: "单位", width: "80", hidden: false},
        { name: "total_quantity", label: "累计销售数量", hidden: false},
        { name: "total_amount", label: "累计销售金额/元", hidden: false},
        { name: "avg_amount", label: "销售均价/元", hidden: false},
        { name: "delivery_num", label: "累计采购数量",width:'120', hidden: false},
        { name: "delivery_money", label: "累计采购金额/元", hidden: false},
        { name: "delivery_avg_amount", label: "采购均价/元", hidden: false},
        { name: "total_profit", label: "累计毛利/元", hidden: false},
        // { name: "profit", label: "平均毛利/元", hidden: false},
        { name: "avg_profit", label: "平均毛利/元", hidden: false},
        { name: "avg_profit_rate", label: "平均毛利率", hidden: false},
        { name: "loss_num", label: "损耗数量", hidden: false},
        { name: "loss_amount", label: "损耗金额", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          city: '',
          merchant_id: '',
          address_id: '',
          date: '',
          distr: [],
          distr_start: '',
          distr_end: '',
          start: '',
          end: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total_amount: 0, // 销售额
        total_delivery_money: 0, // 成本价
        total_gross: 0, // 毛利
        total_rate:0, // 毛利率
      },
      areaArr: [],
      merchantArr: [],
      storeArr: [],
      selpickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.isRestart = false
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => { //查询时间跨度为31天
          if (this.curveTime && this.isRestart) {
            return // 存在选中的日期且没有重新选择日期 不做禁用处理
          }
          if (this.pickerMinDate !== '') {
            const one = 31 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    this.table.params.distr[0] = this.$moment(start).format("YYYY-MM-DD");
    this.table.params.distr[1] = this.$moment(end).format("YYYY-MM-DD");
    this.getTable();
    this.getAddress();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _date = this.table.params.date;
      if(_date &&  _date.length > 0) {
        _params.start = _date[0]
        _params.end = _date[1]
      } else {
        _params.start = '';
        _params.end = '';
      }
      let _distr = this.table.params.distr;
      if(_distr &&  _distr.length > 0) {
        _params.distr_start = _distr[0]
        _params.distr_end = _distr[1]
      } else {
        _params.distr_start = '';
        _params.distr_end = '';
      }
      delete _params.date
      delete _params.distr
      this.table.loading = true;
      this.$http.get('/admin/stat/goods', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_amount = res.data.total_amount; // 销售额
          this.table.total_delivery_money = res.data.total_delivery_money; //采购成本
          this.table.total_gross = res.data.total_gross; // 毛利
          if(Number(res.data.total_amount) > 0) {
            this.table.total_rate = (((Number(res.data.total_gross)*100) / (Number(res.data.total_amount)*100)) *100).toFixed(2) * 1; // 毛利率 = 毛利 / 销售额 （四舍五入保留两位小数）
          } else {
            this.table.total_rate = 0; // 毛利率
          }
        }
      })
    },
    // 系统内门店的地区
    getAddress() {
      this.$http.get('/admin/stat/address', {params:{}}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    // 导出经销明细
    mergeexportData(url) {
      let _date = this.table.params.distr || [];
      if(!this.table.params.address_id || (_date.length < 1 )) {
        return this.$message.warning("请先选择配送时间段和食堂")
      }
      let str = ""
      let obj = {
        merchant_id: this.table.params.merchant_id,
        address_id: this.table.params.address_id,
        start: _date[0] || '',
        end: _date[1] || '',
      }
      Object.keys(obj).forEach(item => {
          str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 全部导出
    exportData(url) {
      let str = ""
      let _params = {...this.table.params}
      let _date = this.table.params.date;
      if(_date &&  _date.length > 0) {
        _params.start = _date[0]
        _params.end = _date[1]
      } else {
        _params.start = '';
        _params.end = '';
      }
      let _distr = this.table.params.distr;
      if(_distr &&  _distr.length > 0) {
        _params.distr_start = _distr[0]
        _params.distr_end = _distr[1]
      } else {
        _params.distr_start = '';
        _params.distr_end = '';
      }
      delete _params.date
      delete _params.distr
      let obj = {
        ..._params
      }
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    tableRowClassNameFun({row, rowIndex}) {
      if(row.avg_profit_rate <= 18) {
        return 'warning-row'
      } {
        return ''
      }
    }
  }
}
</script>
