<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.type" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">商品分拣进度</el-radio-button>
      <el-radio-button :label="2">客户分拣进度</el-radio-button>
      <el-radio-button :label="3">分类分拣进度</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="配送日期" prop="distr_date">
          <el-date-picker
            v-model="table.params.distr_date"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品名称" prop="goods_id" v-if="table.params.type == 1">
          <el-select v-model="table.params.goods_id" filterable placeholder="请选择" clearable @focus="getGoods" @change="onSearch">
            <el-option
              v-for="item in goodsArr"
              :key="item.id"
              :label="item.good_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id" v-if="table.params.type == 2">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="onSearch">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset2"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <VTable
      has-pagionation
      :field="toStr({1:field,2:field2,3:field3},table.params.type)"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 下单分类 -->
      <template v-slot:order_cate_name="{row}">
        <span>{{row.order_cate_name}}</span>
        <span v-if="!!row.order_tow_name"> / {{row.order_tow_name}}</span>
      </template>
      <template v-slot:rate="{row}">
        <span v-if="row.rate == 0.00">0</span>
        <span v-else-if="row.rate > 100">100%</span>
        <span v-else>{{row.rate}}%</span>
      </template>
     <template v-slot:sorter_status="{row}">
        <span>{{toStr({1:'未分拣',2:'部分分拣',3:'分拣完成'},row.sorter_status)}}</span>
     </template>
    </VTable>
   
  </div>
</template>

<script>
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";

export default {
  name: "",
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "good_id", label: "商品ID", width:'80', hidden: false },
        { name: "good_name", label: "商品名称", width:'160', hidden: false },
        { name: "order_cate_name", label: "下单分类", width:'120', hidden: false},
        { name: "spec_unit_name", label: "单位", hidden: false},
        { name: "spec_name", label: "规格", hidden: false},
        { name: "sum_quantity", label: "订单数量/件", hidden: false},
        { name: "total_sorter_num", label: "已分拣数量", hidden: false},
        { name: "rate", label: "分拣进度", hidden: false},
        { name: "sorter_status", label: "分拣状态", hidden: false},
      ],
      field2: [
         { name: "merchant_id", label: "客户ID", width:'80', hidden: false },
        { name: "merchant_name", label: "客户名称", hidden: false},
        { name: "store_name", label: "食堂名称", hidden: false},
        { name: "sum_quantity", label: "订单数量", hidden: false},
        { name: "total_sorter_num", label: "已分拣数量", hidden: false},
        { name: "rate", label: "分拣进度", hidden: false},
        { name: "sorter_status", label: "分拣状态", hidden: false},
      ],
      field3: [
        { name: "order_cate_id", label: "分类ID", width:'80', hidden: false },
        { name: "order_cate_name", label: "下单分类", width:'120', hidden: false},
        { name: "num", label: "商品项数", hidden: false},
        { name: "sum_quantity", label: "下单数量", hidden: false},
        { name: "total_sorter_num", label: "已分拣数量", hidden: false},
        { name: "rate", label: "分拣进度", hidden: false},
        { name: "sorter_status", label: "分拣状态", hidden: false},
      ],
      multipleSelection: [],
      table: {
        loading: false,
        params: {
          type: 1, //状态：1:商品 2:客户 3:缺货
          goods_id:'',
          order_cate_arr: [],
          order_cate_id: '', // 下单分类
          order_cate_tow: '', // 二级下单分类
          date: '',
          distr_date: '',
          city: '',
          sorter_status: '',
          merchant_id: '', // 客户id

          page: 1,
          count: 20,
        },
        data: [],
        total: 0,
      },
      merchantArr: [],
      goodsArr: [],
      orderCateArr: [], //下单分类
    };
  },
  created() {
    this.table.params.distr_date =this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 默认明天
    // this.table.params.date = this.$moment(new Date()).format("YYYY-MM-DD"); // 默认当天
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = { ...this.table.params }
      if(_params.type == 3 ){
        delete _params.goods_id;
      } else if (_params.type == 2) {
        delete _params.order_cate_id;
        delete _params.order_cate_tow;
        delete _params.goods_id;
        delete _params.city;
      } else if(_params.type == 1) {
        delete _params.city;
      }
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http
        .get('/admin/sorter_order/list', {params: _params})
        .then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
            this.$nextTick(() => {
              // 替换文本把食堂换成用户
              this.replaceTextInElement(this.$el, '食堂', '用户'); 
            })
          }
        });
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 20;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = '';
      this.table.params.order_cate_tow = '';
      this.getTable();
    },
    // 获取商品
    getGoods() {
      this.$http.post("/admin/goods/list", {page:1,count:10000}).then((res) => {
        if(res.code == 1) {
          this.goodsArr = res.data.list;
        }
      })
    },
    
  },
};
</script>