<template>
  <el-dialog
    append-to-body
    width="560px"
    title="退货"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    >
    <el-form
      class="staff-from"
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='80px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item label="商品名称:" prop="quantity">
        <span>{{curRow.good_name}}</span>
      </el-form-item>
      <el-form-item label="商品规格:" prop="quantity">
        <span>{{curRow.spec_name}}</span>
      </el-form-item>
      <el-form-item label="退货原因" prop="refund_type">
        <el-select v-model="form.data.refund_type" placeholder="请选择" style="width:100%;">
          <el-option label="质量问题" :value="1"></el-option>
          <el-option label="发错货" :value="2"></el-option>
          <el-option label="漏发少发" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退货数量" prop="refund_quantity">
        <el-input v-model="form.data.refund_quantity" 
        :placeholder="`最大可输入${curRow.quantity}`"
        @input="form.data.refund_quantity=form.data.refund_quantity.match(/\d+(\.\d{0,5})?/) ? form.data.refund_quantity.match(/\d+(\.\d{0,5})?/)[0] : ''">
          <span slot="suffix">{{curRow.spec_unit_name}}</span>
        </el-input>
      </el-form-item>
      <el-form-item label="问题描述" prop="refund_desc">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入"
          v-model="form.data.refund_desc">
        </el-input>
      </el-form-item>
      <el-form-item label="上传凭证" prop="refund_pictures" >
        <el-upload
          action="/api/common/qiniu/upload"
          :limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
          class="imgWrap"
          style="width: 148px; height: 148px;overflow:hidden;"
          accept="image/jpeg, image/png, image/gif"
        >
          <img v-if="form.data.refund_pictures" :src="form.data.refund_pictures" style="object-fit: cover;width:100%;" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="处理方式" prop="deal_type">
        <el-radio-group v-model="form.data.deal_type">
          <el-radio :label="1">退货入库</el-radio>
          <el-radio :label="2">退还供应商</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click='confirm' :loading="loading">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'OrderDetailRejected',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        curRow: {},
        form: {
          loading: false,
          data: {
            detail_id: '', //明细ID
            refund_type: '', //退货原因 1:质量问题 2:发错货 3:漏发少发
            refund_quantity: '', //退货数量
            refund_desc: '', //问题描述
            refund_pictures: '', //退货凭证
            deal_type: '' //处理方式 1:退货入库 2:退还供应商
          },
          rules: {
            refund_type: [{ required: true, message:'请选择', trigger: 'change'}],
            refund_quantity: [{ required: true, message:'请输入', trigger: 'change'}],
            refund_desc: [{ required: true, message:'请输入', trigger: 'change'}],
            refund_pictures: [{ required: true, message:'请上传', trigger: 'change'}],
            deal_type: [{ required: true, message:'请选择', trigger: 'change'}],
          }
        },
        fileList: [],
        dialogImageUrl: '',
        dialogVisibleUrl: false,
      }
    },
    methods: {
      getDetail(row) {
        this.curRow = row;
      },
      reset(done) {
        this.isChange = false;
        this.fileList = [];
        this.$refs.elFormDom.resetFields()
        this.$emit('refresh')
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        this.form.data.refund_pictures = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate('refund_pictures');
      },
      handleRemove(file, fileList) {
        this.fileList = [];
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传图片大小不能超过 20MB!');
        }
        return isLt20M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              ...this.form.data,
              detail_id: this.curRow.id, // 明细ID
            };
            console.log(_params)
            // return
            this.form.loading = true;
            this.$http.post('/admin/order/applyRefund', _params).then(res => {
              if(res.code === 1) {
                this.$message.success("操作成功！")
                this.toggle(false)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
<style scoped>
.item-row {
  margin-top: 5px;
}
</style>
