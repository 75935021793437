<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-row>
          <el-col :span="24">
            <el-form-item label="账单月份" prop="year">
              <el-date-picker
                v-model="table.params.year"
                type="year"
                placeholder="选择月"
                :clearable="false"
                value-format="yyyy"
                format="yyyy"
                @change="changeYear"
                class="yearwrap">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="" prop="quarter">
              <el-select v-model="table.params.quarter" placeholder="季度" clearable @change="selQuarte" class="yearwrap">
                <el-option label="第一季度" :value="1"></el-option>
                <el-option label="第二季度" :value="2"></el-option>
                <el-option label="第三季度" :value="3"></el-option>
                <el-option label="第四季度" :value="4"></el-option>
              </el-select>
              <span
                :class="table.params.month_str == v ? 'monthV acColor' : 'monthV' "
                v-for="(v,i) in this.monthObj"
                :key="v"
                @click="selMonth(v,i)"
              >
                <span>{{v}}月</span>
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="采购日期" prop="day">
          <el-date-picker
            v-model="table.params.day"
            type="date"
            placeholder="请选择"
            format="yyyy 年 MM 月 dd"
            value-format="yyyy-MM-dd"
            :clearable="false"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="supplier_id" clearable @focus="getSupplierSel" @change="getTable">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" plain @click="exportDataAll('/admin/purchase_stat/export')" >批量导出</el-button>
        <span style="margin-left:20px;color:#f56c6c;font-size:14px;font-weight:bold;">采购总额: {{table.total_delivery_money}}元</span>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:surplus_num="{row}">
        <span :class="Number(row.surplus_num) < 0 ? 'redColor' : ''" >{{row.surplus_num}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">查看</el-button>
        <el-button type="text" @click="exportData(row)">导出</el-button>
      </template>
    </VTable>
    <!-- 明细详情 -->
    <detail ref="detail" :sleDate="table.params" @refresh="getTable"></detail>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierIndex',
  components: {
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "delivery_date", label: "采购日期", hidden: false },
        { name: "spec_count", label: "商品项数", hidden: false},
        { name: "num", label: "采购数量", hidden: false},
        { name: "delivery_num", label: "收货数量",  hidden: false},
        { name: "delivery_money", label: "采购金额(元)",  hidden: false},
        { name: "out_num", label: "出库数量",  hidden: false},
        { name: "surplus_num", label: "剩余库存",  hidden: false},
        { name: "change_time", label: "更新时间",  hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          year: '',//年份
          month: '', // 当前月
          quarter:'', // 季度
          month_str:'',
          supplier_id: '',
          day: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total_delivery_money: 0, // 采购总额
      },
      supplierArr: [],
      monthObj:['01','02','03','04','05','06','07','08','09','10','11','12'],
    }
  },
  created() {
    this.table.params.year = new Date().getFullYear().toString(); // 默认当前年份
    this.table.params.month_str = this.$moment(new Date()).format("MM"); // 默认当前年份
    this.table.params.month = new Date().getFullYear().toString() +'-'+ this.$moment(new Date()).format("MM"); // 默认当前月份
    
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      _params.month = !!this.table.params.quarter ? '' : this.table.params.month;
      delete _params.month_str
      this.table.loading = true;
      this.$http.get('/admin/purchase_stat/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_delivery_money = res.data.total_delivery_money;//采购总额

        }
      })
    },
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.month_str = this.$moment(new Date()).format("MM"); // 默认当前年份
      this.table.params.month = new Date().getFullYear().toString() +'-'+ this.$moment(new Date()).format("MM"); // 默认当前月份
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.multipleSelection = [];
      this.getTable();
    },
    // 选择年份
    changeYear(value) {
      this.table.params.month = value + '-' + this.table.params.month_str;
      this.getTable();
    },
    // 选季度
    selQuarte(value) {
      if(!!value) { // 选择季度时自动清除月份
        this.table.params.month_str = '';
        this.table.params.month = '';
      } else { // 清空季度时默认当前月
        this.table.params.month_str = this.$moment(new Date()).format("MM"); // 默认当前年份
        this.table.params.month = new Date().getFullYear().toString() +'-'+ this.$moment(new Date()).format("MM"); // 默认当前月份
      }
      this.getTable();
    },
    // 选月份
    selMonth(v,i) {
      this.table.params.quarter = ''; // 选择月份时自动清除季度
      this.table.params.month_str = v;
      this.table.params.month = this.table.params.year +'-'+v;
      this.getTable();
    },
    // 下单明细
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      let obj = {
        ...row,
        year: this.table.params.year, // 下单年份
        month: this.table.params.month, // 下单月份
        supplier_id: this.table.params.supplier_id, // 供应商id
      }
      dom.getDetail(obj)
      dom = null
    },
    exportData(row) {
      this.multipleSelection = [row];
      this.exportDataAll('/admin/purchase_stat/export')
      this.multipleSelection = [];
    },
    exportDataAll(url) {
      let obj = { ...this.table.params }
      // 没有勾选数据默认是全部导出，勾选了数据就是批量导出
      if(this.multipleSelection.length > 0) {
        obj.days = this.multipleSelection.map(v => { return v.delivery_date}).join("|")
      } else {
        this.$message.warning("请至少选择一条数据")
        return
      }
      let data = {
        url: url,
        obj: obj,
      }
      this.exportFun(data)
    },
    exportFun(data) {
      let obj = data.obj;
      let url = data.url;
      let str = ""
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
  }
}
</script>
<style scoped>
.monthV { 
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #595959;
  border: solid 1px #eee;
  padding: 3px 10px;
  border-radius: 5px;
}
.acColor {
  color: #409eff;
  border: solid 1px #409eff;
}
.yearwrap {
  width: 100px;
}
 ::v-deep .yearwrap .el-input__inner {
  width: 100px !important;
}
</style>