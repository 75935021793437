var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-aside',{attrs:{"width":"auto"}},[_c('el-menu',{staticClass:"menu__wrap el-menu-vertical-demo",attrs:{"router":"","default-active":_vm.$route.path,"collapse":_vm.isCollapse,"unique-opened":false,"active-text-color":"#2cb167"}},[_c('el-menu-item',{attrs:{"index":"/home/index"}},[_c('div',{staticClass:"icon-home"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("数据总览")])]),_vm._l((_vm.routes),function(item){return _c('el-submenu',{key:item.name,class:(item.meta.requiresAuthAll && (_vm.roleType == 2 || _vm.roleType == 3)) ||
        (item.meta.requiresAuth && _vm.roleType == 3) ||
        (item.meta.requiresAffairs && _vm.roleType == 4) ||
        (_vm.open_pick == 2 && item.path == '/sorting') ||
        item.meta.isShow == false
          ? 'display'
          : '',attrs:{"index":item.path}},[[_c('template',{slot:"title"},[_c('div',{class:'icon-' + item.path.substr(1)}),_c('span',[_vm._v(_vm._s(item.meta.title))])]),_c('el-menu-item-group',[_vm._l((item.children),function(child){return [_c('el-menu-item',{key:child.name,class:(child.meta.requiresAuthAll &&
                  (_vm.roleType == 2 || _vm.roleType == 3)) ||
                (child.meta.requiresAuth && _vm.roleType == 3) ||
                (child.meta.requiresAffairs && _vm.roleType == 4) ||
                child.meta.isShow == false
                  ? 'display'
                  : ''
                  ? 'display'
                  : '',attrs:{"index":child.path}},[_vm._v(_vm._s(child.meta.title)+" ")])]})],2)]],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }