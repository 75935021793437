<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="供应商" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="名称/联系人/联系方式"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="table.params.status"
            placeholder="请选择"
            clearable
            @change="onSearch"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width: 100%"
            @change="onSearch"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button
          :size="subUnitSize"
          type="primary"
          icon="el-icon-plus"
          @click="showEdit"
          >创建</el-button
        >
      </div>
    </div>

    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :tree-props="{ children: 'children', hasChildren: 'has_child' }"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province_str="{ row }">
        <span>{{ row.province_str }}{{ row.city_str }}</span>
      </template>
      <template v-slot:merchant_name="{ row }">
        <template v-if="!!row.merchant_name && row.merchant_name.length > 0">
          <div v-for="(v, i) in row.merchant_name" :key="i">
            <span v-if="!!v">{{ v }}</span>
            <span v-if="i < row.merchant_name.length - 1">，</span>
          </div>
        </template>
        <span v-else></span>
      </template>
      <template v-slot:goods_count="{ row }">
        <el-link
          style="padding: 0 10px"
          type="primary"
          @click="selGoods(row)"
          >{{ row.goods_count }}</el-link
        >
      </template>
      <template v-slot:status="{ row }">
        <span :style="{ color: `${row.status == 1 ? '' : '#f56c6c'}` }">{{
          row.status == 1 ? "启用" : "禁用"
        }}</span>
      </template>
      <template v-slot:is_sorter="{ row }">
        <span>{{ row.is_sorter == 1 ? "是" : "否" }}</span>
      </template>
      <!-- 评分 -->
      <template v-slot:score="{ row }">
        <el-rate v-model="row.score" disabled text-color="#ff9900"> </el-rate>
      </template>
      <template v-slot:is_send="{ row }">
        <el-switch
          v-model="row.is_send"
          :active-value="1"
          :inactive-value="2"
          :active-text="`已${row.is_send == 1 ? '开启' : '关闭'}`"
          @change="handleSend(row)"
        >
        </el-switch>
      </template>
      <template v-slot:action="{ row }">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="handleOperate(row)">{{
          row.status == 1 ? "禁用" : "启用"
        }}</el-button>
        <el-button type="text" @click="remove(row)">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

    <!-- 绑定供货商品 -->
    <SelGoods
      :dialogTitle="dialogTitle"
      ref="selGoods"
      :getDataApi="getDataApi"
      :setDataApi="setDataApi"
      :showBind="showBind"
      :sourceType="sourceType"
      @refresh="getTable"
    >
    </SelGoods>
  </div>
</template>

<script>
import Edit from "./components/Edit.vue";
import SelGoods from "@/components/SelGoods.vue";
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
import { mapGetters } from "vuex";
import { getStorage } from "@/storage";

export default {
  name: "SupplierIndex",
  components: {
    Edit,
    SelGoods,
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(["areaArr"]),
  },
  data() {
    return {
      roleType: getStorage("role_type"), // 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      getDataApi: "/admin/supplier/goodsList",
      setDataApi: "/admin/supplier/patch",
      showBind: true,
      sourceType: 1, // 供应商
      field: [
        { name: "id", label: "ID", width: "80", hidden: false },
        { name: "name", label: "供应商名称", hidden: false },
        { name: "contact", label: "联系人", hidden: false },
        { name: "contact_phone", label: "联系方式", hidden: false },
        { name: "account", label: "登录账号", hidden: false },
        { name: "province_str", label: "所在地区", hidden: false },
        {
          name: "merchant_name",
          label: "供应客户",
          width: "150",
          hidden: false,
        },
        { name: "goods_count", label: "商品数量", width: "80", hidden: false },
        { name: "is_sorter", label: "提供代分拣", width: "100", hidden: false },
        { name: "score", label: "综合评分", width: "150", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "is_send", label: "短信通知", width: "120", hidden: false },
        { name: "create_name", label: "创建人", width: "120", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "160",
          fixed: "right",
          hidden: false,
        },
      ],
      table: {
        loading: false,
        params: {
          keyword: "",
          status: "",
          area: [],
          province: "",
          city: "",
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
      },
      dialogTitle: "负责供货商品",
      message: false,
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _area = this.table.params.area;
      if (_area.length == 1) {
        this.table.params.province = _area[0];
      } else if (_area.length == 2) {
        this.table.params.province = _area[0];
        this.table.params.city = _area[1];
      } else {
        this.table.params.province = "";
        this.table.params.city = "";
      }
      this.table.loading = true;
      this.$http
        .get("/admin/supplier/list", { params: this.table.params })
        .then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list.map((item) => {
              return {
                ...item,
                score: Number(item.score),
              };
            });
            this.table.total = res.data.total;
          }
        });
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    //负者供货商品
    selGoods(row) {
      let dom = this.$refs.selGoods;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    // 禁用
    handleOperate(row) {
      this.setConfirm(
        `确认禁用操作？`,
        { id: row.id },
        "/admin/supplier/operation"
      );
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        { id: row.id },
        "/admin/supplier/delete"
      );
    },
    // 短信通知
    handleSend(row) {
      this.$http
        .post("/admin/supplier/open", { id: row.id })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(
              `短信${row.is_send == 1 ? "开启" : "关闭"}成功！`
            );
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.getTable();
        });
    },
  },
};
</script>
