<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-row>
          <el-col :span="24">
            <el-form-item label="账单月份" prop="year">
              <el-date-picker
                v-model="table.params.year"
                type="year"
                placeholder="选择月"
                :clearable="false"
                value-format="yyyy"
                format="yyyy"
                @change="changeYear"
                class="yearwrap"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="" prop="quarter">
              <el-select
                v-model="table.params.quarter"
                placeholder="季度"
                clearable
                @change="selQuarte"
                class="yearwrap"
              >
                <el-option label="第一季度" :value="1"></el-option>
                <el-option label="第二季度" :value="2"></el-option>
                <el-option label="第三季度" :value="3"></el-option>
                <el-option label="第四季度" :value="4"></el-option>
              </el-select>
              <span
                :class="
                  table.params.month_str == v ? 'monthV acColor' : 'monthV'
                "
                v-for="(v, i) in this.monthObj"
                :key="v"
                @click="selMonth(v, i)"
              >
                <span>{{ v }}月</span>
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="客户名称" prop="merchant_id">
          <el-select
            v-model="table.params.merchant_id"
            filterable
            placeholder="请选择"
            clearable
            @focus="getMerchantSel"
            @change="changeMerchant"
          >
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select
            v-model="table.params.address_id"
            filterable
            placeholder="请输入"
            clearable
            @focus="getStoreSel"
            @change="onSearch"
          >
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px"
                >({{ item.merchant_name }})</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="day">
          <el-date-picker
            v-model="table.params.day"
            type="date"
            placeholder="请选择"
            format="yyyy 年 MM 月 dd"
            value-format="yyyy-MM-dd"
            clearable
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd flex">
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="exportDataAll('/admin/bill/merchantLogExport')"
          >批量导出</el-button
        >
        <div
          style="
            margin-left: 10px;
            color: #f56c6c;
            font-size: 14px;
            font-weight: bold;
          "
        >
          汇总：
          <span style="margin-right: 20px"
            >订单金额:{{ table.total_amount }}</span
          >
          <span style="margin-right: 20px"
            >采购金额:￥{{ table.total_purchase_amount }}</span
          >
          <span style="margin-right: 20px"
            >运费:￥{{ table.total_freight }}</span
          >
          <span style="margin-right: 20px"
            >毛利:￥{{ table.total_profit }}</span
          >
          <span style="margin-right: 20px"
            >毛利率:{{ table.total_profit_rate }}%</span
          >
        </div>
      </div>
    </div>

    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :tree-props="{ children: 'children', hasChildren: 'has_child' }"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      :tableRowClassName="tableRowClassNameFun"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
      @sortchange="sortchange"
    >
      <template v-slot:profit="{ row }">
        <span :class="Number(row.profit) < 0 ? 'redColor' : ''">{{
          row.profit
        }}</span>
      </template>
      <template v-slot:action="{ row }">
        <el-button type="text" @click="showDetail(row)">查看</el-button>
        <el-button type="text" @click="exportData(row)">导出</el-button>
      </template>
    </VTable>
    <!-- 明细详情 -->
    <detail
      ref="detail"
      :sleDate="table.params"
      @refresh="getTable"
      :day="table.params.day"
    ></detail>
  </div>
</template>

<script>
import Detail from "./components/Detail.vue";
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
import { mapGetters } from "vuex";

export default {
  name: "SupplierIndex",
  components: {
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(["areaArr"]),
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "store_name", label: "食堂名称", hidden: false },
        { name: "merchant_name", label: "所属客户", hidden: false },
        { name: "order_count", label: "有效订单笔数", hidden: false },
        { name: "total_quantity", label: "下单数量", hidden: false },
        {
          name: "total_amount",
          label: "订单金额(元)",
          hidden: false,
          sortable: "custom",
        },
        {
          name: "total_purchase_price",
          label: "采购金额(元)",
          hidden: false,
          sortable: "custom",
        },
        { name: "freight", label: "运费(元)", hidden: false },
        {
          name: "profit",
          label: "毛利(元)",
          hidden: false,
          sortable: "custom",
        },
        { name: "profit_rate", label: "毛利率(%)", hidden: false },
        { name: "change_time", label: "更新时间", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "100",
          fixed: "right",
          hidden: false,
        },
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          year: "", //年份
          month: "", // 当前月
          quarter: "", // 季度
          month_str: "",
          merchant_id: "",
          address_id: "",
          page: 1,
          count: 10,
          order: 0,
          desc: 1,
          day: "",
        },
        data: [],
        total: 0,
        total_amount: 0,
        total_purchase_amount: 0,
        total_freight: 0,
        total_profit: 0,
        total_profit_rate: 0,
      },
      merchantArr: [],
      storeArr: [],
      monthObj: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
  created() {
    this.table.params.year = new Date().getFullYear().toString(); // 默认当前年份
    this.table.params.month_str = this.$moment(new Date()).format("MM"); // 默认当前年份
    this.table.params.month =
      new Date().getFullYear().toString() +
      "-" +
      this.$moment(new Date()).format("MM"); // 默认当前月份

    this.getTable();
  },
  methods: {
    getTable() {
      let _params = { ...this.table.params };
      _params.month = !!this.table.params.quarter
        ? ""
        : this.table.params.month; // 当选择季度时月份值清空
      delete _params.month_str;
      this.table.loading = true;
      this.$http
        .get("/admin/bill/merchant", { params: _params })
        .then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
            this.table.total_amount = res.data.total_amount;
            this.table.total_purchase_amount = res.data.total_purchase_amount;
            this.table.total_freight = res.data.total_freight;
            this.table.total_profit = res.data.total_profit;
            this.table.total_profit_rate = res.data.total_profit_rate;
          }
        });
    },
    sortchange({ column, prop, order }) {
      console.log(column, prop, order);
      if (order == "ascending") {
        this.table.params.desc = 1;
      } else if (order == "descending") {
        this.table.params.desc = 2;
      }
      if (prop == "total_amount") {
        this.table.params.order = 1;
      }
      if (prop == "total_purchase_price") {
        this.table.params.order = 2;
      }
      if (prop == "profit") {
        this.table.params.order = 3;
      }
      this.table.params.page = 1;
      this.getTable();
    },
    reset() {
      this.$refs.elFormDom.resetFields();
      this.table.params.month_str = this.$moment(new Date()).format("MM"); // 默认当前年份
      this.table.params.month =
        new Date().getFullYear().toString() +
        "-" +
        this.$moment(new Date()).format("MM"); // 默认当前月份
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.multipleSelection = [];
      this.getTable();
    },
    // 选择年份
    changeYear(value) {
      this.table.params.month = value + "-" + this.table.params.month_str;
      this.getTable();
    },
    // 选季度
    selQuarte(value) {
      if (!!value) {
        // 选择季度时自动清除月份
        this.table.params.month_str = "";
        this.table.params.month = "";
        this.table.params.day = "";
      } else {
        // 清空季度时默认当前月
        this.table.params.month_str = this.$moment(new Date()).format("MM"); // 默认当前年份
        this.table.params.month =
          new Date().getFullYear().toString() +
          "-" +
          this.$moment(new Date()).format("MM"); // 默认当前月份
      }
      this.getTable();
    },
    // 选月份
    selMonth(v, i) {
      this.table.params.quarter = ""; // 选择月份时自动清除季度
      this.table.params.month_str = v;
      this.table.params.month = this.table.params.year + "-" + v;
      this.table.params.day = "";
      this.getTable();
    },
    // 下单明细
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true);
      let obj = {
        ...row,
        year: this.table.params.year, // 下单年份
        month: this.table.params.month, // 下单月份
        quarter: this.table.params.quarter, // 季度
      };
      dom.getDetail(obj);
      dom = null;
    },
    exportData(row) {
      this.multipleSelection = [row];
      this.exportDataAll("/admin/bill/merchantLogExport");
      this.multipleSelection = [];
    },
    exportDataAll(url) {
      let obj = { ...this.table.params };
      let data = {
        url: url,
        obj: obj,
      };
      // 没有勾选数据默认是全部导出，勾选了数据就是批量导出
      if (this.multipleSelection.length > 0) {
        obj.address_ids = this.multipleSelection
          .map((v) => {
            return v.address_id;
          })
          .join("|");
        this.exportFun(data);
      } else {
        this.$confirm("您未勾选食堂，确认要导出全部食堂报表吗 ", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.exportFun(data);
          })
          .catch(() => {});
      }
    },
    exportFun(data) {
      let obj = data.obj;
      let url = data.url;
      let str = "";
      Object.keys(obj).forEach((item) => {
        str += `&${item}=${obj[item]}`;
      });
      console.log(
        `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
      );
      window.open(
        `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
      );
    },
    tableRowClassNameFun({row, rowIndex}) {
      if(row.profit_rate <= 18) {
        return 'warning-row'
      } {
        return ''
      }
    }
  },
};
</script>
<style scoped>
.monthV {
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #595959;
  border: solid 1px #eee;
  padding: 3px 10px;
  border-radius: 5px;
}
.acColor {
  color: #409eff;
  border: solid 1px #409eff;
}
.yearwrap {
  width: 100px;
}
::v-deep .yearwrap .el-input__inner {
  width: 100px !important;
}
</style>