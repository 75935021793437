<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="操作类型" prop="action">
          <el-select
            v-model="table.params.action"
            filterable
            placeholder="请选择操作类型"
            clearable
            @change="onSearch"
          >
            <el-option label="新增" :value="1"></el-option>
            <el-option label="编辑" :value="2"></el-option>
            <el-option label="删除" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作内容" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="请输入操作内容"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作时间" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            placeholder="选择操作时间"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-link :underline="false" type="danger"
        >温馨提示：系统将为您保留最近三个月的订单、采购单、商品供应商、发货单模版修改日志</el-link
      >
    </div>

    <VTable
      showIndex
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :tree-props="{ children: 'children', hasChildren: 'has_child' }"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:client_type="{ row }">
        <span>{{
          {
            1: "pc端",
            2: "采购端小程序",
            3: "仓管端APP",
            4: "配送员端APP",
            5: "配送员端小程序",
            6: "小程序商户",
          }[row.client_type]
        }}</span>
      </template>
    </VTable>
  </div>
</template>

<script>
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";

export default {
  name: "",
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "action_name", label: "操作类型", hidden: false, width: "160" },
        {
          name: "operator_context",
          label: "操作内容",
          hidden: false,
        },
        { name: "create_time", label: "操作时间", hidden: false, width: "160" },
        { name: "operator_name", label: "操作人", hidden: false, width: "160" },
        {
          name: "client_type",
          label: "操作端口",
          hidden: false,
          width: "120",
        },
      ],
      table: {
        loading: false,
        params: {
          keyword: "",
          date: "",
          action: "",
          page: 1,
          count: 20,
        },
        data: [],
        total: 0,
      },
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http
        .get("/admin/log/list", { params: this.table.params })
        .then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
          }
        });
    },
  },
};
</script>
