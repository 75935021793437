<template>
  <div>
    <div class="flex">
      <el-input :value="amount" placeholder="请输入" class="input ui" disabled>
        <template slot="prepend">￥</template></el-input
      >
      <el-button
        type="success"
        icon="el-icon-plus"
        circle
        @click="add"
      ></el-button>
    </div>

    <div class="flex mar" v-for="(item, index) in store" :key="index">
      <sekectapi :store_id.sync="item.store_id" />
      <el-input
        placeholder="请输入金额"
        class="elinput uin"
        v-model="item.amount"
        @input="handleInput(item)"
      >
        <template slot="append">元</template>
      </el-input>
      <el-button
        v-show="store.length > 1 || index > 0"
        type="danger"
        icon="el-icon-minus"
        circle
        @click="del(index)"
      ></el-button>
    </div>
  </div>
</template>
<script>
import sekectapi from "@/components/sekectapi";
export default {
  props: {
    storelist: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { sekectapi },
  data() {
    return {
      store: [
        {
          store_id: "",
          amount: "", //运费
        },
      ],
    };
  },
  watch: {
    store: {
      handler(val) {
        this.$emit("input", this.store);
      },
      deep: true,
    },
    storelist: {
      handler(val) {
        this.store = val;
      },
      deep: true,
    },
  },
  computed: {
    amount() {
      return (
        this.store
          .reduce((pre, cur) => pre + Number(cur.amount), 0)
          .toFixed(3) * 1
      );
    },
  },
  methods: {
    reset() {
      this.store = [
        {
          store_id: "",
          amount: "", //运费
        },
      ];
    },
    add() {
      this.store.push({
        store_id: "",
        amount: "", //运费
      });
    },
    del(index) {
      this.store.splice(index, 1);
    },
    handleInput(item) {
      const regex = /^(\d+)?(\.\d+)?$/;
      if (item.amount[0] == ".") {
        item.amount = "0.";
      }
      if (
        item.amount[item.amount.length - 1] === "." &&
        item.amount.length - 1 == item.amount.indexOf(".")
      )
        return (item.amount = item.amount.replace(/\.+/gi, "."));
      if (!regex.test(item.amount)) {
        item.amount = item.amount.substring(0, item.amount.length - 1);
      }
    },
  },
  mounted() {
    if (this.storelist.length > 0) {
      this.store = this.storelist;
    }
  },
};
</script>
<style scoped>
.input {
  width: 460px;
  margin: 0 20px 0 0;
}
.elinput {
  width: 220px;
}
.mar {
  margin-top: 20px;
}
.uin {
  margin: 0 20px;
}
</style>