<template>
  <div>
    <div class="flex">
      <div class="">
        <div class="flex rel">
          <el-input
            placeholder="搜索采购单号"
            clearable
            v-model="keyword"
            @input="search(true)"
            class="el-input"
          ></el-input>
          <el-select
            clearable
            v-model="supplier_id"
            filterable
            remote
            reserve-keyword
            placeholder="请选择供应商"
            :remote-method="remoteMethod"
            @change="change"
            class="el-input"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="group">
          <el-checkbox
            v-show="!city.checked"
            v-for="city in cities"
            :label="city.id"
            :key="city.id"
            v-model="city.checked"
            @change="() => handleCheckedCitiesChange(city)"
            >{{ city.name }}</el-checkbox
          >
        </div>
      </div>
      <div class="group1 pa">
        <div v-show="checkedCities.length">已关联采购单:</div>
        <el-checkbox-group v-model="ischeckedCities" @change="handleChecked">
          <el-checkbox
            v-for="city in checkedCities"
            :label="city.id"
            :key="city.id"
            >{{ city.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <!-- <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      >供应商 ───── 采购单号 ───── 收货金额</el-checkbox
    > -->

    <el-pagination
      v-show="cities.length"
      @current-change="changePage"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    date: String,
    purchase_idarr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isIndeterminate: false,
      checkAll: false,
      checkedCities: [],
      cities: [],
      ischeckedCities: [],
      keyword: "",
      total: 0,
      page: 1,
      options: [],
      supplier_id: "",
    };
  },
  watch: {
    date(val) {
      this.cities = [];
      this.checkedCities = [];
      this.ischeckedCities = [];
      this.$emit("input", "");
      val && this.search();
    },
    ischeckedCities() {
      this.$emit("input", this.ischeckedCities.join(","));
    },
  },
  methods: {
    reset() {
      this.checkedCities = [];
      this.cities = [];
      this.ischeckedCities = [];
      this.keyword = "";
      this.supplier_id = "";
      this.total = 0;
      this.page = 1;
    },
    remoteMethod(query) {
      this.$http
        .get("/admin/supplier/allList", {
          params: { page: 1, count: 10000, keyword: query },
        })
        .then((res) => {
          if (res.code == 1) {
            this.options = res.data.list;
          }
        });
    },
    change() {
      this.search(true);
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(city) {
      let checked = city.checked;
      if (checked && this.ischeckedCities.indexOf(city.id) === -1) {
        this.ischeckedCities.push(city.id);
      }
      if (!checked && this.ischeckedCities.indexOf(city.id) > -1) {
        this.ischeckedCities.splice(this.ischeckedCities.indexOf(city.id), 1);
      }
      let ids = this.checkedCities.map((item) => item.id);
      if (checked && ids.indexOf(city.id) === -1) {
        this.checkedCities.push(city);
      }
      if (!checked && ids.indexOf(city.id) > -1) {
        this.checkedCities.splice(ids.indexOf(city.id), 1);
      }
    },
    handleChecked(v) {
      this.checkedCities = this.checkedCities.filter((item) => {
        this.cities.forEach((city) => {
          if (city.id === item.id) {
            city.checked = v.indexOf(item.id) > -1;
          }
        });
        return v.indexOf(item.id) > -1;
      });
    },
    search(t) {
      this.page = 1;
      this.date && this.getTable(t);
    },
    changePage(page) {
      this.page = page;
      this.getTable();
    },
    getTable() {
      this.$http
        .get("/admin/purchase_order/list", {
          params: {
            delivery_date: this.date,
            page: this.page,
            count: 10,
            order_no: this.keyword,
            supplier_id: this.supplier_id,
          },
        })
        .then((res) => {
          if (res.code === 1) {
            this.total = res.data.total;
            this.cities = res.data.list.map((item) => {
              return {
                id: item.id,
                name:
                  item.supplier_name +
                  " - " +
                  item.order_no +
                  " - ￥" +
                  item.delivery_money,
                checked:
                  this.ischeckedCities.length &&
                  (this.ischeckedCities.indexOf(item.id) > -1 ||
                    this.purchase_idarr.indexOf(item.id + "") > -1),
              };
            });
            // if (!t) {
            //   this.checkedCities = this.cities
            //     .filter((item) => {
            //       return this.purchase_idarr.indexOf(item.id + "") > -1;
            //     })
            //     .map((item) => {
            //       return {
            //         id: item.id,
            //         name: item.name,
            //       };
            //     });
            // }
          }
        });
    },
    setCheckedKeys(date) {
      if (this.purchase_idarr.length) {
        this.ischeckedCities = this.purchase_idarr.map((item) => item * 1);
      }
      this.$http
        .get("/admin/purchase_order/list", {
          params: {
            delivery_date: date,
            page: this.page,
            count: 100,
            order_ids: this.purchase_idarr.join(","),
          },
        })
        .then((res) => {
          if (res.code === 1) {
            this.checkedCities = res.data.list.map((item) => {
              return {
                id: item.id,
                name:
                  item.supplier_name +
                  " - " +
                  item.order_no +
                  " - ￥" +
                  item.delivery_money,
                checked: true,
              };
            });
          }
        });
    },
  },
  mounted() {
    this.remoteMethod("");
    this.getTable();
  },
};
</script>
<style scoped>
.group {
  width: 450px;
  margin: 10px 0 0;
}
.el-input {
  width: 220px;
}
.rel {
  position: relative;
}
.pa {
  position: sticky;
  right: 0;
}
.group1 {
  width: 400px;
  overflow: hidden;
}
</style>