<template>
  <div class="main-view">
    <el-radio-group
      v-model="table.params.type"
      class="tabsWrap"
      @change="handleTabs"
    >
      <el-radio-button :label="1">按商品分拣</el-radio-button>
      <el-radio-button :label="2">按订单分拣</el-radio-button>
      <el-radio-button :label="3">缺货商品</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="配送日期" prop="distr_date">
          <el-date-picker
            v-model="table.params.distr_date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            :clearable="false"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <span v-show="table.params.type == 1 || table.params.type == 3">
          <el-form-item label="商品搜索" prop="goods_id">
            <el-select
              v-model="table.params.goods_id"
              filterable
              remote
              placeholder="请输入"
              :remote-method="remoteMethod"
              :loading="selLoading"
              clearable
              @change="onSearch"
              value-key="goods_id"
            >
              <el-option
                v-for="item in goodsArr"
                :label="item.good_name"
                :value="item.id"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </span>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable
            placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{
              children: 'child',
              label: 'name',
              value: 'id',
              checkStrictly: true,
            }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="分拣状态" prop="status">
          <el-select
            v-model="table.params.status"
            clearable
            placeholder="请选择"
            @change="onSearch"
          >
            <el-option label="待分拣" :value="1"></el-option>
            <el-option label="部分分拣" :value="2"></el-option>
            <el-option label="分拣完成" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <span v-show="table.params.type == 2">
          <el-form-item label="客户名称" prop="merchant_id">
            <el-select
              v-model="table.params.merchant_id"
              filterable
              placeholder="请输入"
              @focus="getMerchantSel"
              @change="onSearch"
            >
              <el-option
                v-for="item in merchantArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单编号" prop="order_no">
            <el-input
              v-model.trim="table.params.order_no"
              clearable
              placeholder="请输入"
              @keydown.enter.native="onSearch"
            ></el-input>
          </el-form-item>
        </span>
        <span v-show="table.params.type == 1 || table.params.type == 2">
          <el-form-item label="单位" prop="unit">
            <el-select
              v-model="table.params.unit"
              clearable
              placeholder="请选择"
              @change="onSearch"
            >
              <el-option label="计重单位" :value="1"></el-option>
              <el-option label="非计重单位" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="超出预警值" prop="is_notice">
            <el-select
              v-model="table.params.is_notice"
              clearable
              placeholder="请选择"
              @change="onSearch"
            >
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </span>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width: 100%"
            @change="onSearch"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="供应商/采购员"
          prop="supplier_id"
          v-if="table.params.type != 3"
        >
          <el-select
            v-model="table.params.supplier_id"
            filterable
            id="bind_id"
            clearable
            placeholder="请输入"
            @focus="getSupplierSel"
            @change="onSearch"
          >
            <el-option
              v-for="item in supplierArr"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button
          v-if="table.params.type == 3"
          :size="subUnitSize"
          type="primary"
          @click="showAdd"
          >生成采购单</el-button
        >
        <template v-if="table.params.type != 3">
          <el-button type="text" :size="subUnitSize" @click="handleWarning">
            <i class="el-icon-edit-outline" style="font-size: 0.875rem"></i>
            <span class="is_warning">分拣超标预警值：{{ notice_value }}%</span>
          </el-button>
          <div style="float: right">
            <el-radio-group
              v-model="selectAction"
              :size="subUnitSize"
              class="sortingRadioBtn"
              @change="handleBatch"
            >
              <el-radio-button :label="2">批量分拣完成</el-radio-button>
              <el-radio-button :label="1">批量打印</el-radio-button>
            </el-radio-group>
            <!-- <el-button
              :size="subUnitSize"
              type="warning"
              @click="handleBatch(2)"
            >批量分拣完成</el-button>
            <el-button
              :size="subUnitSize"
              type="warning"
              @click="handleBatch(1)"
            >批量打印</el-button> -->
            <el-button
              v-if="table.params.type != 3"
              :size="subUnitSize"
              type="primary"
              plain
              icon="el-icon-printer"
              @click="downloadPrint"
              >下载打印机安装包</el-button
            >
          </div>
        </template>
      </div>
    </div>

    <VTable
      ref="multipleTable"
      has-pagionation
      :field="toStr({ 1: field, 2: field2, 3: field3 }, table.params.type)"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :tree-props="{ children: 'children', hasChildren: 'has_child' }"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="is_showSelect"
      :selectAction="selectAction"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:good_name="{ row }">
        <div>{{ row.good_name }}</div>
        <!-- 标准名称和商品名称相同时不显示标准名称 -->
        <div
          v-if="!!row.norm_name && row.norm_name != row.good_name"
          style="font-size: 0.75rem; color: #ff5b5b"
        >
          ({{ row.norm_name }})
        </div>
        <!-- sum_lack_num > 0 标示缺货  -->
        <div v-if="row.sum_lack_num > 0" class="lack_num">缺</div>
      </template>
      <!-- 下单数量 -->
      <template v-slot:sum_quantity="{ row }">
        <div>{{ row.sum_quantity }}{{ row.spec_unit_name }}</div>
      </template>
      <!-- 已分拣数量 (是否超标 1:是  2:否,超标字体标红)-->
      <template v-slot:total_sorter_num="{ row }">
        <!-- <div>{{row.total_sorter_num}}{{row.total_sorter_num > 0 ? row.spec_unit_name : ''}}</div> -->
        <div :class="row.is_notice == 1 ? 'redColor' : ''">
          {{ row.total_sorter_num
          }}{{ row.total_sorter_num > 0 ? row.spec_unit_name : "" }}
        </div>
      </template>
      <!-- 待分拣数量 = 下单数量 - 已分拣数量 -->
      <template v-slot:stay_quantity="{ row }">
        <span v-if="Number(row.sum_quantity) - Number(row.total_sorter_num) < 1"
          >0</span
        >
        <span v-else
          >{{
            (Number(row.sum_quantity) - Number(row.total_sorter_num)).toFixed(
              2
            ) * 1
          }}{{ row.spec_unit_name }}</span
        >
      </template>
      <!-- 客户名称 -->
      <template v-slot:merchant_name="{ row }">
        <div>{{ row.store_name }}</div>
        <div style="color: #aeaeae">({{ row.merchant_name }})</div>
      </template>
      <!-- 下单分类 -->
      <template v-slot:order_cate_name="{ row }">
        <span>{{ row.order_cate_name }}</span>
        <span v-if="!!row.order_tow_name"> / {{ row.order_tow_name }}</span>
      </template>
      <template v-slot:sorter_status="{ row }">
        <span>{{
          toStr(
            { 1: "待分拣", 2: "部分分拣", 3: "分拣完成" },
            row.sorter_status
          )
        }}</span>
      </template>
      <template v-slot:action="{ row }">
        <template v-if="table.params.type != 3">
          <el-button type="text" @click="showDetail(row)">{{
            row.sorter_status == 3 ? "查看" : "分拣"
          }}</el-button>
          <el-button type="text" @click="handlePrint(row)">打印标签</el-button>
        </template>
        <el-button
          type="text"
          @click="handleCancelLack(row)"
          v-if="table.params.type == 3"
          >取消缺货</el-button
        >
      </template>
    </VTable>
    <div
      v-if="multipleSelection.length > 0 && !!is_showSelect"
      style="position: absolute; bottom: 24px; left: 35px"
    >
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-check"
        @click="handleConfirm"
        >选好了</el-button
      >
    </div>

    <!-- 批量打印提示弹窗 -->
    <el-dialog
      title="确认打印标签吗？"
      :visible.sync="dialogVisiblePrint"
      width="450px"
      :before-close="handleClose"
      center
    >
      <!-- 批量打印 提示 -->
      <template v-if="multipleSelection.length > 0 && !!is_batch">
        <div style="margin-bottom: 10px">
          <span>{{
            table.params.type == 1 ? "商品项数：" : "订单数量："
          }}</span>
          <span style="font-weight: bold">{{ multipleSelection.length }}</span>
        </div>
        <div>
          标签数量：
          <span style="font-weight: bold">{{ detailsArr.length }}</span>
        </div>
      </template>
      <!-- 单条打印 提示 type 1:按商品分拣 2:按订单分拣 -->
      <template v-else>
        <div v-if="table.params.type == 1">
          商品名称：<span style="font-weight: bold">{{
            currRow.good_name
          }}</span>
        </div>
        <div v-if="table.params.type == 2">
          客户名称：<span style="font-weight: bold"
            >{{ currRow.store_name }}（{{ currRow.merchant_name }}）</span
          >
        </div>
        <div style="margin-top: 10px">
          标签数量：<span style="font-weight: bold">{{
            currRow.detailsCount
          }}</span>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-print="'myPrintBatch'"
          @click="handlePrintbatchFun"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 生成采购单、 设置超标预警值 -->
    <el-dialog
      :title="set_warning == false ? '生成采购单' : '设置超标预警值'"
      :visible.sync="dialogVisible"
      width="25rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center
    >
      <el-form
        class="staff-from"
        ref="elFormDom"
        label-position="right"
        :model="form.data"
        :size="subUnitSize"
      >
        <el-form-item
          label="采购地区"
          prop="city"
          :rules="[
            {
              required: true,
              message: '请选择地址',
              trigger: ['blur', 'change'],
            },
          ]"
          v-if="set_warning == false"
        >
          <el-cascader
            v-model="form.data.city"
            :options="areaArr"
            :props="{ checkStrictly: false, emitPath: false }"
            clearable
            filterable
            style="width: 100%"
          >
          </el-cascader>
        </el-form-item>
        <!-- 设置超标预警值 -->
        <el-form-item
          label=""
          prop="num"
          :rules="[
            {
              required: true,
              message: '请输入预警值',
              trigger: ['blur', 'change'],
            },
          ]"
          v-if="set_warning == true"
        >
          <el-input
            v-model="form.data.num"
            placeholder="请输入预警值"
            @input="form.data.num = form.data.num.replace(/[^0-9-]/g, '')"
          >
            <span slot="suffix">%</span>
          </el-input>
        </el-form-item>
        <el-alert
          title="分拣数量除以下单数量之比,大于设置的预警值则会提示分拣员，请合理设置预警值"
          type="warning"
          :closable="false"
        >
        </el-alert>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" @click="confirm"
            >确定</el-button
          >
        </el-row>
      </template>
    </el-dialog>

    <!-- 批量打印区域 -->
    <div id="myPrintBatch" ref="myPrintBatch">
      <div
        class="printContent"
        v-for="(item, index) in batchPrintData"
        :key="index"
        style="pading-left: 15px"
      >
        <div class="print-title">
          {{ item.store_name }}
        </div>
        <div class="print-flex">
          <div>
            <div class="print-con" style="margin-bottom: 10px">
              <span>{{ item.good_name }}</span>
            </div>
            <div class="print-con">
              下单数量：{{ item.quantity }}{{ item.spec_unit_name }}
            </div>
            <div class="print-con">
              分拣数量：{{
                item.sorter_status == 1 ? item.quantity : item.sorter_num
              }}{{ item.spec_unit_name }}
            </div>
          </div>
          <div>
            <div class="qrcode" ref="qrCodeUreBatch"></div>
          </div>
        </div>
        <div class="print-text" style="margin-left: 5px; margin-top: 2px">
          备注：{{ item.remark }}
        </div>
        <div
          class="print-text"
          style="text-align: right; margin-top: 2px; margin-bottom: 20px"
        >
          {{ $moment(new Date()).format("yyyy-MM-DD HH:mm:ss") }}
        </div>
      </div>
    </div>

    <!-- 按商品分拣详情 -->
    <GoodsSorting ref="GoodsSorting" @refresh="getTable"></GoodsSorting>
    <!-- 按订单分拣详情 -->
    <ClientSorting ref="ClientSorting" @refresh="getTable"></ClientSorting>
  </div>
</template>

<script>
import GoodsSorting from "./components/GoodsSorting.vue";
import ClientSorting from "./components/ClientSorting.vue";
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
import { getStorage } from "@/storage";
import { v4 as uuid } from "uuid";
import QRCode from "qrcodejs2";

export default {
  name: "",
  components: {
    GoodsSorting,
    ClientSorting,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      roleType: getStorage("role_type"), // 1:超级管理员，2:管理员, 3：员工
      currPrintObj: {},
      isShow: false,
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "good_id", label: "商品ID", width: "80", hidden: false },
        { name: "good_name", label: "商品名称", width: "160", hidden: false },
        { name: "order_cate_name", label: "下单分类", hidden: false },
        { name: "spec_name", label: "规格", hidden: false },
        { name: "spec_unit_name", label: "单位", hidden: false },
        { name: "sum_quantity", label: "下单数量", hidden: false },
        { name: "total_sorter_num", label: "已分拣数量", hidden: false },
        { name: "stay_quantity", label: "待分拣数量", hidden: false },
        { name: "sorter_status", label: "分拣状态", hidden: false },
        { name: "create_time", label: "更新时间", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "120",
          fixed: "right",
          hidden: false,
        },
      ],
      field2: [
        { name: "merchant_id", label: "客户ID", width: "80", hidden: false },
        {
          name: "merchant_name",
          label: "客户名称",
          width: "180",
          hidden: false,
        },
        { name: "order_no", label: "订单编号", hidden: false },
        { name: "num", label: "商品项数", hidden: false },
        { name: "sum_quantity", label: "下单数量", hidden: false },
        { name: "total_sorter_num", label: "已分拣数量", hidden: false },
        { name: "sorter_status", label: "分拣状态", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "120",
          fixed: "right",
          hidden: false,
        },
      ],
      field3: [
        { name: "good_id", label: "商品ID", width: "80", hidden: false },
        { name: "good_name", label: "商品名称", width: "160", hidden: false },
        { name: "order_cate_name", label: "下单分类", hidden: false },
        { name: "spec_unit_name", label: "单位", hidden: false },
        { name: "spec_name", label: "规格", hidden: false },
        { name: "total_lack_num", label: "缺货数量", hidden: false },
        { name: "city_name", label: "地区", hidden: false },
        { name: "lack_time", label: "最新标记时间", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "100",
          fixed: "right",
          hidden: false,
        },
      ],
      table: {
        loading: false,
        params: {
          type: 1, //状态：1:商品 2:客户 3:缺货
          goods_id: "",
          order_cate_arr: [],
          order_cate_id: "",
          order_cate_tow: "",
          date: "",
          area: [],
          province: "",
          city: "",
          status: "",
          merchant_id: "", // 客户id
          order_no: "", // 订单编号
          unit: "",
          is_notice: "",
          distr_date: "", // 配送日期
          supplier_id: "", // 供应商/采购员
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
      },
      dialogVisible: false,
      form: {
        loading: false,
        data: {
          city: "",
          num: "", // 设置超标预警值
        },
      },
      selLoading: false,
      merchantArr: [],
      areaArr: [],
      goodsArr: [], // 商品列表
      orderCateArr: [], // 下单分类
      set_warning: false, // 分拣超标预警值
      notice_value: 0, // 预警值
      supplierArr: [], // 供应商/采购员

      multipleSelection: [],
      detailsArr: [],
      myPrintBatch: {
        id: "myPrintBatch",
        preview: false, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
      },
      batchPrintData: [], // 批量打印数据
      myUUID: "", // 存放生成的UUID值
      dialogVisiblePrint: false,
      currRow: {
        details: [],
      },
      is_batch: false,
      is_showSelect: false,
      selectAction: 0,
    };
  },
  created() {
    this.table.params.distr_date = this.$moment(
      new Date().getTime() + 60 * 60 * 24 * 1000
    ).format("YYYY-MM-DD"); // 默认明天
    // this.table.params.date = this.$moment(new Date()).format("YYYY-MM-DD"); // 默认当天
    this.getTable();
    this.getArea(); // 省市区
  },
  methods: {
    getTable() {
      this.multipleSelection = [];
      let _params = { ...this.table.params };
      if (_params.type == 3) {
        delete _params.type;
        delete _params.merchant_id;
        delete _params.status;
        delete _params.order_no;
        delete _params.supplier_id;
      } else if (_params.type == 2) {
        delete _params.cate_id;
        delete _params.goods_id;
        // delete _params.supplier_id;
      } else if (_params.type == 1) {
        delete _params.merchant_id;
        delete _params.order_no;
      }
      let _area = this.table.params.area;
      if (_area.length == 1) {
        _params.province = _area[0];
      } else if (_area.length == 2) {
        _params.province = _area[0];
        _params.city = _area[1];
      } else {
        _params.province = "";
        _params.city = "";
      }
      delete _params.order_cate_arr;
      let urlApi =
        this.table.params.type == 3
          ? "/admin/sorter_order/lackList"
          : "/admin/sorter_order/list";
      this.table.loading = true;
      this.$http.get(urlApi, { params: _params }).then((res) => {
        if (res.code === 1) {
          this.table.loading = false;
          this.notice_value = res.data.notice_value;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          if (res.data.list.length > 0) {
            this.batchPrintData = res.data.list[0].details;
          }
        }
      });
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.data = [];
      this.selectAction = 0;
      this.is_showSelect = false;
      this.getTable();
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields();
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = "";
      this.table.params.order_cate_tow = "";
      this.getTable();
    },
    // 获取全国省市区
    getArea() {
      this.$http.post("/common/district/all", { is_all: 1 }).then((res) => {
        if (res.code === 1) {
          this.areaArr = res.data.list;
          for (const key in this.areaArr) {
            // 删除第三层children属性
            for (const i in this.areaArr[key].children) {
              delete this.areaArr[key].children[i].children;
            }
          }
        }
      });
    },
    // 获取商品
    getGoods() {
      this.$http
        .post("/admin/goods/list", { page: 1, count: 10000 })
        .then((res) => {
          if (res.code == 1) {
            this.goodsArr = res.data.list;
          }
        });
    },
    showDetail(row) {
      this.isShow = true;
      console.log(this.isShow, 1);
      // type 1:按商品分拣，2按订单分拣
      if (this.isShow) {
        let dom =
          this.table.params.type == 1
            ? this.$refs.GoodsSorting
            : this.$refs.ClientSorting;
        dom.toggle(true);
        let obj = {
          ...row,
          distr_date: this.table.params.distr_date, // 配送日期
        };
        dom.getDetail(
          obj,
          this.table.params.type,
          this.table.params.supplier_id
        );
        dom = null;
      }
    },

    // 取消缺货
    handleCancelLack(row) {
      this.setConfirm(
        `请确认取消？`,
        { spec_id: row.spec_id, city: row.city_id },
        "/admin/sorter_order/cancelLack"
      );
    },
    showAdd() {
      if (this.table.total > 0) {
        this.dialogVisible = true;
      } else {
        this.$message.warning("没有缺货商品!");
      }
    },
    // 分拣超标预警值
    handleWarning() {
      this.dialogVisible = true;
      this.set_warning = true;
    },
    // 生成采购单时计算缺货数量
    countNum(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += Number(item.total_lack_num);
      });
      return sum;
    },
    // 确认
    confirm() {
      this.$refs.elFormDom.validate((valid) => {
        if (valid) {
          if (this.set_warning == false) {
            // 缺货生成采购单
            let arr = this.table.data.filter((v) => {
              return v.city_id == this.form.data.city;
            });
            console.log(arr);
            let count = this.countNum(arr);
            this.$confirm(
              `商品项数${arr.length}， 数量${count}`,
              "请确定采购",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.purchaseFun(); //生成采购单方法
              })
              .catch(() => {});
          } else if (this.set_warning == true) {
            // 设置预警值
            this.form.loading = true;
            this.$http
              .post("/admin/sorter_order/setNotice", {
                value: this.form.data.num,
              })
              .then((res) => {
                if (res.code == 1) {
                  this.dialogVisible = false;
                  this.getTable();
                }
              })
              .finally(() => {
                this.form.loading = false;
              });
          }
        }
      });
    },
    // 生成采购单方法
    purchaseFun() {
      this.form.loading = true;
      this.$http
        .post("/admin/sorter_order/purchase", { city: this.form.data.city })
        .then((res) => {
          if (res.code == 1) {
            this.dialogVisible = false;
            this.getTable();
          }
        })
        .finally(() => {
          this.form.loading = false;
        });
    },
    // 查询商品
    remoteMethod(query) {
      if (query !== "") {
        this.selLoading = true;
        this.$http
          .post("/admin/goods/list", {
            page: 1,
            count: 10000,
            good_name: query,
          })
          .then((res) => {
            if (res.code == 1) {
              this.goodsArr = res.data.list;
            }
          })
          .finally(() => {
            this.selLoading = false;
          });
      } else {
        this.goodArr = [];
      }
    },
    // 下载打印机安装包
    downloadPrint() {
      window.open("https://qn.cdn.jszhr.com/xprinter.exe");
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisiblePrint = false;
      this.$refs.elFormDom.resetFields();
      this.form.data.city = "";
      this.set_warning = false;
    },
    // 列表打印按钮
    handlePrint(row) {
      this.is_batch = false; //是否批量打印
      this.dialogVisiblePrint = true;
      this.currRow = row;
      this.batchPrintData = row.details;
    },
    // 批量操作按钮
    handleBatch() {
      this.is_showSelect = true;
      // this.$refs.multipleTable.clearSelection(); // 清空用户的选择
    },
    //全部分拣完成计算分拣数量：判断已分拣数量sorter_num如果等于零的话取下单数量quantity，否则取已分拣数量sorter_num
    countNum1(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += Number(item.sorter_num) == 0 ? Number(item.quantity) : 0;
        // sum += Number(item.sorter_num) == 0 ? Number(item.quantity) : Number(item.sorter_num);
      });
      return sum;
    },
    // 选好了
    handleConfirm() {
      // 取details商品详情列表，取二级数组组成一个新数组
      this.detailsArr = this.multipleSelection.reduce(
        (acc, item) => acc.concat(item.details),
        []
      );
      // selectAction 2:批量分拣完成 1:批量打印
      if (this.selectAction == 2) {
        this.batchSorterData = this.detailsArr.filter(
          (v) => v.sorter_status != 3
        ); // 需要分拣完成的数据，过滤掉已经分拣完成的
        let num = this.countNum1(this.batchSorterData); // 计算分拣总数量
        let _spdetail_listec = this.batchSorterData.map((v) => {
          return {
            detail_id: v.id, //明细ID
            num: !!v.num ? v.num : '',
          };
        });
        let _params = {
          // id: this.currRow.id,
          type: 2, //类型 1:部分分拣 2:全部分拣
          detail_list: _spdetail_listec,
        };
        console.log(_params);
        this.$confirm(
          `${this.table.params.type == 1 ? "商品" : "订单"}数：${
            this.multipleSelection.length
          }，   数量：${num}`,
          "分拣完成",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }
        )
          .then(() => {
            // return
            this.$http
              .post("/admin/sorter_order/allSorter", _params)
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success("操作成功！");
                  this.multipleSelection = [];
                  this.getTable();
                }
              });
          })
          .catch(() => {});
      } else if (this.selectAction == 1) {
        //批量打印
        this.batchPrintData = this.detailsArr; // 需要打印的数据
        this.is_batch = true; //是否批量打印
        this.dialogVisiblePrint = true;
      }
    },
    handlePrintbatchFun() {
      this.is_showSelect = false;
      this.dialogVisiblePrint = false;
      this.batchPrintData.forEach((v, i) => {
        this.currPrintObj = v;
        this.myUUID = uuid().replace(/-/g, "/"); // myUUID将字符串中的 '-' 替换成 '/'
        // takeNum分拣数量：待分拣时取下单数量，部分分拣或分拣完成取已分拣数量
        var takeNum = v.sorter_status == 1 ? v.quantity : v.sorter_num;
        this.$refs["qrCodeUreBatch"][i].innerHTML = ""; //生成二维码之前先清除上一次的
        new QRCode(this.$refs.qrCodeUreBatch[i], {
          text: `CODE_JSZ_JIN_CAI_ID-${v.order_no}-${v.id}-${takeNum}-${this.myUUID}`,
          width: 70,
          height: 70,
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });

      this.myPrintBatch = {
        id: "myPrintBatch",
        popTitle: "打印", // 打印配置页上方标题
        extraHead: "", //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
        preview: true, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
      };
    },
  },
  destroyChild() {
    this.$refs.child.$destroy();
  },
};
</script>
<style media="print" lang="scss">
.printContent {
  display: none;
}
.print-title {
  padding: 0.3125rem;
  margin: 0.3125rem;
  border-bottom: 0.0625rem solid #000;
}
.print-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.3125rem;
}
.print-con {
  padding: 0.1875rem 0;
}
.print-text {
  margin: 0 0.3125rem;
}

@page {
  size: auto;
  margin: 0 2mm;
}
@media print {
  html {
    background-color: #ffffff;
    height: auto;
    margin: 0;
  }
  body {
    border: solid 0.0625rem #ffffff;
    margin: 0 0.3125rem;
    padding: 0 0.3125rem;
    font-size: 0.625rem;
  }
  .printContent {
    margin: 0 0.3125rem;
  }
  .print-title {
    font-size: 1.125rem;
    margin-top: 0;
    margin-bottom: 0.3125rem;
    padding: 0.3125rem;
  }
  .print-con {
    font-size: 1rem;
  }
  .layout__wrap {
    height: auto !important;
  }
  .main-view {
    height: auto !important;
  }
  .printContent {
    display: block;
    margin-left: 10px;
  }
}
</style>


<style scoped>
.main-view {
  overflow: auto;
}
.main-view::-webkit-scrollbar {
  width: 0px;
}
.main-view::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 0px;
}
.mr20 {
  margin-right: 1.25rem;
}
.chTable .el-table__expanded-cell {
  padding: 0 !important;
}
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
  background: #fff;
  flex: 1;
}
.pagination__wrap {
  margin: 0.625rem 0;
  text-align: right;
  /* padding-right: 2.5rem; */
}
.goodsbox {
  width: 10%;
  display: inline-block;
}
.good {
  display: flex;
  align-items: center;
}
.good-info {
  margin-left: 0.9375rem;
  color: #898989;
}
.yellow {
  color: #ff803b;
}
.lack_num {
  background: #eaf7f0;
  color: #2cb167;
  width: 1.875rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.is_warning {
  font-size: 0.875rem;
  padding-bottom: 0.1875rem;
}
.is_warning:hover {
  border-bottom: solid 0.0625rem #43b375;
}
</style>

<style scoped lang="scss">
.sortingRadioBtn {
  &::v-deep {
    .el-radio-button:first-child .el-radio-button__inner {
      border-radius: 4px !important;
    }
    .el-radio-button:last-child .el-radio-button__inner {
      border-radius: 4px !important;
    }
    .el-radio-button {
      margin-right: 10px !important;
    }
    .el-radio-button__inner {
      border: 1px solid #abe0c2 !important;
      border-radius: 4px;
      color: #2cb167;
      background: #eaf7f0;
    }
    .el-radio-button__inner:hover {
      color: #fff;
      background: #2cb167;
    }
    .el-radio-button.is-active .el-radio-button__inner {
      color: #fff;
      background: #2cb167;
    }
  }
}
</style>