<template>
  <div class="el-input">
    <el-select
      v-model="address_id"
      filterable
      placeholder="请输入"
      clearable
      @change="onSearch"
    >
      <el-option
        v-for="item in storeArr"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px"
          >({{ item.merchant_name }})</span
        >
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: ["store_id"],
  data() {
    return {
      address_id: "",
      storeArr: [],
    };
  },
  watch: {
    store_id(val) {
      this.address_id = val;
    },
  },
  methods: {
    getStoreSel() {
      this.$http
        .get("/admin/path/storeList", { params: { page: 1, count: 10000 } })
        .then((res) => {
          if (res.code === 1) {
            this.storeArr = res.data.list;
          }
        });
    },
    onSearch() {
      this.$emit("update:store_id", this.address_id);
    },
  },
  mounted() {
    this.getStoreSel();
    this.address_id = this.store_id;
  },
};
</script>
<style scoped>
.el-input {
  width: 220px;
}
</style>