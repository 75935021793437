<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="更新日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :clearable="true"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="标准名称" prop="goods_name">
          <el-input v-model="table.params.goods_name" @keydown.enter.native="onSearch" placeholder="请输入标准名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="仓库查询" prop="stash_id">
          <el-select v-model="table.params.stash_id" filterable placeholder="请输入" @focus="getStash" clearable @change="onSearch">
            <el-option
              v-for="item in stashArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.province_str }}{{item.city_str}}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" @click="exportDataAll('/admin/inventory/export')" icon="el-icon-download" >全部导出</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="plexportData('/admin/inventory/export')">批量导出</el-button>
        <el-checkbox v-model="is_zero"  size="mini" style="margin-left:10px" @change="onSearch">过滤0库存</el-checkbox>
      </div>
    </div>
    
    <div class="curTableWrap" ref="main">
      <el-table
        :data="table.data"
        ref="tableDom"
        :height="height"
        border
        size="mini"
        style="width: 100%"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        :header-cell-style="{background:'#f5f5f5'}"
        class="chTable"
      >
        <el-table-column type="selection" width="50" ></el-table-column>
        <el-table-column
          label="标准名称"
          prop="norm_name">
        </el-table-column>
        <el-table-column
          label="下单分类"
          prop="cate_name">
          <template slot-scope="{row}">
            <span>{{row.cate_name}}</span>
            <span v-if="!!row.order_name_tow"> / {{row.order_name_tow}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="单位"
          prop="unit_name">
        </el-table-column>
        <el-table-column
          label="规格"
          prop="spec_names">
          <template slot-scope="{row}">
            <span v-if="row.spec_names.length > 1">存在多个商品规格</span>
            <span v-else>{{row.spec_names[0]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="入库数量"
          prop="quantity">
        </el-table-column>
        <el-table-column
          label="已出库"
          prop="out_quantity">
        </el-table-column>
        <el-table-column
          label="已报损"
          prop="env_quantity">
        </el-table-column>
        <el-table-column
          label="现有库存"
          prop="surplus_quantity"
          sortable="custom"
          width="120">
          <template slot-scope="{row}">
            <span :class="row.surplus_quantity < 0 ? 'redColor' : ''">{{row.surplus_quantity}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="存货单价"
          prop="unit_price">
          <template slot-scope="{row}">
           <span>¥{{row.unit_price}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="库存金额"
          prop="amount"
          sortable="custom"
          width="120"
          >
          <template slot-scope="{row}">
            <span :class="row.amount < 0 ? 'redColor' : ''">¥{{row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          prop="inventory_time"
          width="150">
        </el-table-column>
        <el-table-column
          label="操作"
          prop="action"
          width="190">
          <template slot-scope="{row}">
            <el-button type="text" @click="showDetail(row)">查看明细</el-button>
            <el-button type="text" @click="showExcise(row)">出库</el-button>
            <!-- is_change: 1:能转换 -->
            <el-button v-if="row.is_change == 1" type="text" @click="showUnitcut(row)">单位转换</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class='pagination__wrap'>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 查看明细 -->
    <detail ref="detail"></detail>
    <!-- 出库 -->
    <Excise ref="excise"  @refresh="getTable"></Excise>
    <!-- 单位转换 -->
    <Unitcut ref="unitcut"  @refresh="getTable"></Unitcut>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import Excise from './components/Excise.vue'
import Unitcut from './components/Unitcut.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: 'storeStorckDetail',
  components: {
    Detail,
    Excise,
    Unitcut,
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['goodsTypeArr','areaArr'])
  },
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      is_zero: false, // 是否过滤0 1:是 0:否
     
      height: 0,
      table: {
        loading: false,
        params: {
          cate_id: '',
          order_cate_arr: [],
          order_cate_id: '', // 下单分类
          order_cate_tow: '', // 二级下单分类
          goods_name: '',
          stash_id: '',
          area:[],
          province: '',
          city: '',
          date: [],
          start: '',
          end: '',
          order: '', // 排序字段 1:现有库存 2:库存金额
          desc: '', //排序 1:正序 2:倒序
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      goodsArr: [],
      stashArr: [],
      orderCateArr: [],
    }
  },
  created() {
    // 默认当天
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30); // 往前推一个月
    this.table.params.date[0] = this.$moment(start).format("YYYY-MM-DD");
    this.table.params.date[1] = this.$moment(end).format("YYYY-MM-DD");
    this.getTable();
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.main.offsetHeight || 500
    })
  },
  methods: {
    getTable() {
      this.table.params.is_zero = !!this.is_zero ? 1 : 0;
      let _params = {...this.table.params}
      let _area = this.table.params.area;
      if(_area.length == 1) {
        _params.province = _area[0]
      } else if(_area.length == 2) {
        _params.province = _area[0]
        _params.city = _area[1]
      } else {
        _params.province = '';
        _params.city = '';
      }
      let _date = this.table.params.date;
      if(!!_date && _date.length > 0) {
        _params.start = !!_date ? _date[0] : '';
        _params.end = !!_date ? _date[1] : '';
      } 
      delete _params.date;
      _params.cate_id = this.table.params.order_cate_id;
      delete _params.order_cate_id;
      delete _params.order_cate_arr;
      delete _params.area;
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http.get('/admin/inventory/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = '';
      this.table.params.order_cate_tow = '';
      this.is_zero = '';
      this.getTable();
    },
    // 查看明细
    showDetail(row){
      let dom = this.$refs.detail
      dom.toggle(true)
      let obj = {
        ...row,
        date: this.table.params.date
      }
      dom.getDetail(obj)
      dom = null
    },
    // 出库
    showExcise(row) {
      let dom = this.$refs.excise;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 单位转换
    showUnitcut(row) {
      let dom = this.$refs.unitcut;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 获取仓库
    getStash() {
      this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000}}).then(res => {
        if(res.code == 1) {
          this.stashArr = res.data.list;
        }
      })
    },
    // 批量导出
    plexportData(url) {
      if (this.multipleSelection.length) {
        let ids = this.multipleSelection
          .map((item) => {
              return item.ids;
          }).join("|");
        let str = ""
        let obj = {
          ...this.table.params,
          ids
        }
        console.log(ids)
        Object.keys(obj).forEach(item => {
            str += `&${item}=${obj[item]}`
        })
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    // 全部导出
    exportDataAll(url) {
      let str = ""
      this.table.params.is_zero = !!this.is_zero ? 1 : 0;
      let obj = {...this.table.params}
      let _area = this.table.params.area;
      if(_area.length == 1) {
        obj.province = _area[0]
      } else if(_area.length == 2) {
        obj.province = _area[0]
        obj.city = _area[1]
      } else {
        obj.province = '';
        obj.city = '';
      }
      obj.cate_id = this.table.params.order_cate_id;
      delete obj.area;
      delete obj.order_cate_arr;
      delete obj.order_cate_id;
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },

   
    handleSortChange({ prop, order }) {
      console.log(prop, "prop")
      console.log(order, "type")
      // order排序字段 1:现有库存 2:库存金额
      // desc 排序 1:正序 2:倒序
      if(prop == 'amount') { // 库存金额排序
        this.table.params.order = 2
      } else if(prop == 'surplus_quantity') { // 现有库存排序
        this.table.params.order = 1
      }
      if(order == 'descending') { // 倒序
        this.table.params.desc = 2
      } else if(order == 'ascending') { // 升序
        this.table.params.desc = 1
      }
      this.getTable();
    },
    
  }
}
</script>

<style scoped>
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}
.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
</style>