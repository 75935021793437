import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment';
// import echarts from 'echarts'
import VueClipBoard from 'vue-clipboard2'
import * as $echarts from "echarts";
import "@/plugin/element"
import "normalize.css"
import "element-ui/packages/theme-chalk/src/index.scss";
import "element-ui/lib/theme-chalk/icon.css";
import http from '@/api/http.js'
import Print from 'vue-print-nb'
import "@/util/tianditu.js"
// import { globalTextReplaceMixin } from '@/mixins/globalMixin'; // 引入全局混入
// Vue.mixin(globalTextReplaceMixin); // 全局注册混入
Vue.use(Print)

import Storage from 'vue-ls'
let options = {
  namespace: 'hiPrint-',
  name: 'ls',
  storage: 'local',
};
Vue.use(Storage, options);

Vue.prototype.$http = http
Vue.prototype.$echarts = $echarts
Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(VueClipBoard)


//在出现报错的el-radio-group组件上绑定自定义指令
Vue.use(
  Vue.directive('removeAriaHidden', {
    bind(el, binding) {
      //获取节点
      let ariaEls = el.querySelectorAll('.el-radio__original');
      ariaEls.forEach(item => {
        item.removeAttribute('aria-hidden')
      });
    }
  })
)

// 定义全局指令 v-replace-text
Vue.directive('replace-text', {
  bind(el, binding, vnode) {
    const userId = 5; // 假设你在 Vue 实例的根上存储了用户 ID
    const replaceFrom = '食堂';
    const replaceTo = '用户';

    if (userId === 5) {
      el.textContent = el.textContent.replace(new RegExp(replaceFrom, 'g'), replaceTo);
    }
  },
  update(el, binding, vnode) {
    // 如果文本内容在组件的生命周期内会发生变化，可以在这里更新替换逻辑
    this.bind(el, binding, vnode);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
