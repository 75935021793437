<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          订单详情
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">订单信息</div>
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item v-for="(item, index) in this.detailArr" :key="index" :labelStyle='labelStyle' :label="item.title" v-show="item.isShow == true">{{item.value}}</el-descriptions-item> 
          <el-descriptions-item :labelStyle='labelStyle' label="支付时间" v-if="!!info.pay_time">{{info.pay_time}}</el-descriptions-item>
          <el-descriptions-item :labelStyle='labelStyle' label="状态">{{toStr({0:'待分拣',1:'待配送',2:'待送达',3:'已完成',4:'已取消'},info.status)}}</el-descriptions-item>
        </el-descriptions>

        <div class="itemTime" style="margin-top:10px;">订单进度</div>
        <el-steps :active="stepsActive" class="erorSteps" align-center  finish-status="error" style="margin:20px 0;" v-if="info.status == 4">
          <el-step title="">
            <template #title>
              <span style="font-size:18px;color:#333;">下单</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.create_time}}</div>
            </template>
          </el-step>
          <el-step title="" >
            <template #title>
              <span style="font-size:18px;color:#333;">取消</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.cancel_time}}</div>
            </template>
          </el-step>
        </el-steps>

        <el-steps :active="stepsActive" align-center process-status="wait" style="margin:20px 0;width:70%;" v-else>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">下单</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.create_time}}</div>
            </template>
          </el-step>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">采购完成</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.delivery_time}}</div>
            </template>
          </el-step>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">拣货时间</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.sorter_time}}</div>
            </template>
          </el-step>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">开始配送</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.distr_time}}</div>
            </template>
          </el-step>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">完成</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.finish_time}}</div>
            </template>
          </el-step>
        </el-steps>

        <div class="itemTime">收货信息</div>
        <el-descriptions class="margin-top" title="" :column="3" border>
          <el-descriptions-item  :labelStyle='labelStyle'>
            <template slot="label">
              <i class="el-icon-user"></i>
              用户名
            </template>
            {{info.name}}
          </el-descriptions-item>
          <el-descriptions-item  :labelStyle='labelStyle'>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{info.phone}}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle='labelStyle'>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              联系地址
            </template>
            {{info.province_name}}{{info.city_name}}{{info.area_name}}{{info.address}}
            <!-- 订单状态是待分拣和待配送 可以修改食堂（只能修改一次），选该客户名下的所有其他任一食堂-->
            <!-- status: 0:'待分拣',1:'待配送',2:'待送达',3:'已完成',4:'已取消', update_store:0未修改过食堂，1：修改过食堂 -->
            <el-button v-if="this.info.status < 2 && this.info.update_store == 0" type="primary" plain size="mini" icon="el-icon-edit" @click="handleEdit">修改食堂</el-button>
          </el-descriptions-item>
        </el-descriptions>

        <div class="itemTime" style="margin-top:10px">商品信息</div>
        <el-input
          style="width:240px;margin-bottom:10px;"
          v-model.trim="searchForm.data.goods_name"
          placeholder="请输入商品名称"
          size="small"
          @keydown.enter.native="getDetail(info.id)"
        >
         <el-button slot="append" icon="el-icon-search" @click="getDetail(info.id)"></el-button>
        </el-input>
        <el-table
          :data="tableData"
          border
          :key="tablekey"
          style="width: 100%">
          <el-table-column
            type="index"
            width="60"
            label="ID">
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类"
            width="150">
            <template slot-scope="{row}">
              <span>{{row.order_cate_name}}</span>
              <span v-if="!!row.order_tow_name"> / {{row.order_tow_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_name"
            label="商品名称" width="160">
            <template slot-scope="{row}">
              <div>{{row.good_name}}</div>
              <div v-if="!!row.norm_name" style="color:#999;">标名：{{row.norm_name}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="spec_unit_name"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="spec_name"
            label="商品规格">
            <template slot-scope="{row}">{{row.spec_name}}</template>
          </el-table-column>
          <el-table-column
            prop="rate"
            label="税率">
            <template slot-scope="{row}">
              <span v-if="!!row.rate">{{row.rate}}%</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            width="120"
            label="">
            <template slot="header" >
              <span>售价/元</span>
              <span style="font-size:12px;color:#e6a23c">({{toStr({1:'折后价',2:'参考价'},order_price)}})</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            width="140">
            <template slot-scope="{row}">
              x {{row.quantity}}
              <!-- refund_status 审核通过才会有已退标记 -->
              <span v-if="row.refund_quantity > 0 && row.refund_status == 2" style="color:#f56c6c;font-size:12px;">(已退{{row.refund_quantity}})</span>
              <div v-if="!!row.old_quantity && row.old_quantity != 0" style="text-decoration: line-through;float:right;">原 x{{row.old_quantity}}</div>
              <span v-if="row.status == 4" class="redColor" style="font-size:12px;">(已取消)</span>
            </template>
          </el-table-column> 
          <el-table-column
            prop="amount"
            label="金额/元">
            <template slot-scope="{row}">
              ￥ {{row.amount}}
              <span v-if="!!row.old_amount && row.old_amount != 0" style="text-decoration: line-through;float:right;">原 {{row.old_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注">
          </el-table-column>
          <el-table-column
            prop="purchase_name"
            label="修改采购商品">
          </el-table-column>
          <el-table-column
            prop="sorter_num"
            label="已分拣">
          </el-table-column>
          <el-table-column
            prop="sorter_name"
            label="分拣员">
          </el-table-column>
          <!-- 待分拣没有提货时间和提货人字段 -->
          <template v-if="info.status != 0">
            <el-table-column
              prop="take_time"
              label="提货时间">
            </el-table-column>
            <el-table-column
              prop="take_time"
              label="提货人">
              <template slot-scope>
                <span>{{info.rider_name}}</span>
              </template>
            </el-table-column>
          </template>
          <!-- 已完成状态 或 订单未审核且没有分拣数量的时候才有操作栏 -->
          <el-table-column
            v-if="info.status == 3 || (info.is_check == 2 && info.sorter_num == 0)"
            prop="action"
            label="操作"
            fixed="right"
            :width="(info.status == 3 && info.is_check == 2) ? '150px' : '120px'">
            <template slot-scope="{row}">
              <!-- 只有已完成订单才需要展示退货状态货退货操作 -->
              <template v-if="info.status == 3">
                <el-button v-if="row.refund_status == 0" type="text" @click="handleRejected(row)">退货</el-button>
                <!-- "refund_status" //退货状态：1-申请中；2-已同意；3-已拒绝 -->
                <template v-else>
                  <span v-if="row.refund_status == 1">退货中</span>
                  <span v-else-if="row.refund_status == 3">已拒绝</span>
                  <!-- 已同意（退货数量等于下单数量 则是全部退货，否则部分退货） -->
                  <span v-else>
                    {{row.refund_quantity == row.quantity ? '全部退货' : '部分退货'}}
                  </span>
                </template>
              </template>
              <!-- is_check 是否审核 1:是 2:否  (订单没审核的增加修改采购商品操作)-->
              <!-- 待分拣且分拣数量为0，并且未审核的才有审核操作按钮，部分分拣和其他状态都没有"修改采购商品"操作按钮 -->
              <template v-if=" info.status == 0 && info.sorter_num == 0 && info.is_check == 2">
                <el-link v-if="row.refund_status == 0" type="primary" @click="handleGoods(row)">修改采购商品</el-link>
                <div style="font-size:12px;color:#999;">{{row.purchase_name}}</div>
              </template>
            </template>
          </el-table-column>
        </el-table>
        
        <template v-if="!!info.file">
          <el-divider content-position="left">其他信息</el-divider>
          <template v-if="info.file.includes('.pdf') == true || info.file.includes('.xlsx') == true || info.file.includes('.xls') == true">
            <el-button class="view" type="primary" plain icon="el-icon-view" @click="toWidow(info.file)">查看文件</el-button>
          </template>
          <el-image
            v-else
            style="width: 100px; height: 100px"
            :src="info.file"
            fit="cover" 
            :preview-src-list="[info.file]"></el-image>
        </template>

        <!-- 操作确认送达时上传的签字确认单 -->
        <template v-if="!!info.finish_image.length > 0">
          <el-divider content-position="left">签字确认单</el-divider>
          <el-image
            v-for="img in info.finish_image"
            :key="img"
            style="width: 100px; height: 100px;margin-right:10px;"
            :src="img"
            fit="cover" 
            :preview-src-list="info.finish_image"></el-image>
        </template>
      </div>
    </div>

    <!-- 修改食堂 -->
    <el-dialog
      title="修改食堂"
      :visible.sync="dialogFormVisible"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="食堂名称" prop="address_name">
          <el-select v-model="form.data.address_name" filterable placeholder="请选择" @focus="getStore" @change="changeStore" >
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_name_str">
          <el-select v-model="form.data.contact_name_str" filterable placeholder="请选择" @change="changeContact">
            <el-option v-for="item in storeAdminArr" :key="item.id" :label="item.contact_name" :value="item">
              <span style="float: left">{{ item.contact_name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.phone}}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <div style="text-align:center;">
          <i class="el-icon-info" style="color:#e6a23c;"></i>
          <span style="color:#e6a23c;">仅支持修改一次，请谨慎操作</span>
        </div>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="confirm">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 修改采购商品 -->
    <el-dialog
      title="修改采购商品"
      :visible.sync="dialogVisibleEdit"
      width="400px"
      :before-close="handleClose2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        ref='elFormDom2'
        label-width='100px'
        label-position='right'
        :model='form2.data'
        :rules='form2.rules'
        :size="subUnitSize"
      >
        <el-form-item label="下单商品" >
          <span>{{currRow.good_name}}</span>
        </el-form-item>
        <el-form-item label="采购商品" prop="goods_name">
          <el-select
            v-model="form2.data.goods_name"
            filterable
            remote
            placeholder="请输入"
            :remote-method="remoteMethod"
            :loading="selLoading"
            clearable
            @change="changeGoods">
            <el-option v-for="item in goodsArr" :label="item.good_name" :value="item" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form2.loading" :disabled="form2.loading" @click="handleSubmit">确认修改</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 修改下单商品 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleEdit2"
      width="400px"
      :before-close="handleClose3"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        ref='elFormDomGoods'
        label-width='100px'
        label-position='right'
        :model='formGoods.data'
        :rules='formGoods.rules'
        :size="subUnitSize"
      >
        <el-form-item label="下单商品:" >
          <el-input v-model="form2.data.goods_name" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="单位规格:" >
          <div v-for="(item,index) in selGoodsSpecArr" :key="index" @click="clickSpec(item,index)" style="display:flex;">
            <div :class="index == selindex ? 'spec active' : 'spec'">{{item.unit_name}}/{{item.name}}</div>
            <!-- 客户省委党校并且食堂是朱湖路的售价四舍五入保留一位小数 -->
            <div style="margin-left:8px">¥{{(info.merchant_id == 77 && info.address_id == 76) ? Number(item.price).toFixed(1) : item.price}}</div>
          </div>
        </el-form-item>
        <el-form-item label="下单数量:" prop="num">
          <el-input v-model="formGoods.data.num" :placeholder="formGoods.data.quantity" @input="countPrice"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="formGoods.loading" :disabled="formGoods.loading" @click="submitFun2">确认修改</el-button>
        </el-row>
      </template>
    </el-dialog>


    <!-- 退货 -->
    <Rejected ref="rejected" @refresh="getDetail(info.id)"></Rejected>

  </div>
</template>

<script>
  import Rejected from '@/views/order/list/components/rejected.vue';
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'orderListDetail',
    mixins: [mixinTable],
    components: {
      Rejected
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        labelStyle: { width: '100px' },
        tablekey: new Date()+'',
        info: {
          finish_image:[]
        },
        detailArr: [],
        tableData: [],
        dialogFormVisible: false,
        form: {
          loading: false,
          data: {
            contact_name_str: '',
            address_id: '', //食堂ID
            address_name: '',//食堂名称
            merchant_account_id: '',//门店管理员ID
            contact_name: '', //联系人
            contact_phone: '', //联系电话
          },
          rules: {
            address_name: [
              { required: true, message: "请选择", trigger: "change" },
            ],
            contact_name_str: [
              { required: true, message: "请选择", trigger: "change" },
            ],
          },
        },
        form2: {
          loading: false,
          data: {
            goods_name: '',
            goods_id: '',
          },
          rules: {
            goods_name: [{ required: true, message:'请输入采购商品', trigger: 'blur'}],
          }
        },
        formGoods: {
          loading: false,
          data: {
            num: '',//下单数量
            quantity: '', //反显下单数量
          },
          rules: {
            num: [{ required: true, message:'请输入下单数量', trigger: 'blur'}],
          }
        },
        stepsActive: 1,
        storeArr: [], // 下单食堂
        storeAdminArr:[], // 联系人
        searchForm: {
          data: {
            goods_name: '',
          }
        },
        dialogVisibleEdit: false,
        dialogVisibleEdit2: false,
        currRow: {},
        selLoading: false,
        goodsArr: [],
        selGoodsSpecArr: [],
        selindex: 0,
        order_price: '',
      }
    },
    methods: {
      getDetail(orderId) {
        this.isChange = true;
        this.$http.post('/admin/order/detail', {goods_name: this.searchForm.data.goods_name, id: orderId }).then(res => {
          if(res.code == 1) {
            this.info = res.data;
            this.order_price = res.data.details.length > 0 ? res.data.details[0].order_price : '';
            this.tablekey = new Date()+''; // 刷新表格数据

            this.tableData = res.data.details; // 商品信息
            let _count = res.data.details.filter(item => item.status != 4).length; // 过滤掉状态为4（已取消）的商品
            this.detailArr = [ //订单信息
              { title: "订单编号", value: res.data.order_no },
              { title: "客户名称", value: res.data.merchant_name },
              { title: "下单食堂", value: res.data.store_name },
              { title: "下单账号", value: res.data.merchant_account },
              { title: "商品项数", value: _count},
              { title: "下单数量", value: res.data.spec_count },
              { title: "订单金额", value: `¥${res.data.amount}`},
            ];

            // 订单进度
            if(res.data.status == 1 || res.data.status == 0 ) { 
              if (res.data.status == 1 && !!res.data.delivery_time) { // 待配送且有采购时间就是采购完成状态
                this.stepsActive = 2
              } else if (res.data.status == 1 && !!res.data.sorter_time) { // 待配送且有拣货时间展示拣货时间节点
                this.stepsActive = 3
              } else { // 下单
                this.stepsActive = 1
              }
            } else if(res.data.status == 2) { // 待送达开始配送状态
              this.stepsActive = 4
            } else if(res.data.status == 3) { // 已完成
              this.stepsActive = 5
            } else if(res.data.status == 4) { // 已取消
              this.stepsActive = 2
            }

            this.$nextTick(() => {
              // 替换文本把食堂换成用户
              this.replaceTextInElement(this.$el, '食堂', '用户');
            })

          }
        })
      },
      // 查询商品
      remoteMethod(query) {
        if(query !== '') {
          this.selLoading = true;
          this.$http.post("/admin/goods/list", {page:1,count:10000,good_name: query}).then((res) => {
            if(res.code == 1) {
              this.goodsArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      // 根据客户id查询食堂
      getStore() {
        this.$http.get('/admin/path/storeList', { params:{ page: 1, count: 10000, merchant_id: this.info.merchant_id}}).then(res => {
          if(res.code == 1) {
            if(res.data.list.length > 0) {
               // 过滤出除了订单在用的地址
              this.storeArr = res.data.list.filter(v => v.id != this.info.address_id);
              console.log(this.storeArr, "this.storeArr")
            } else {
              this.storeArr = [];
            }
          }
        })
      },
      // 根据食堂获取收货信息
      changeStore(value) {
        console.log(value)
        this.form.data.address_id = value.id; // 食堂id
        this.form.data.address_name = value.name; // 食堂名称
        this.form.data.contact_name_str = `${value.merchant_account_name} ${value.phone}`; //联系人姓名加手机号展示
        this.form.data.contact_name = value.merchant_account_name; //联系人
        this.form.data.merchant_account_id = value.merchant_account_id; //联系人id
        this.form.data.contact_phone = value.phone; // 联系人电话

        let _params = {
          page:1,
          count: 1000,
          subject_id: value.subject_id, // 主体ID
          merchant_id: value.merchant_id, // 商户ID
          store_id: value.id, // 过滤门店ID
        };
        this.getStoreAdmin(_params)
      },
      getStoreAdmin(_params) {
        this.$http.get('/common/constant/storeAdmin',{params:_params}).then(res => {
          if(res.code == 1) {
            this.storeAdminArr = res.data.list;
          }
        })
      },
      // 选择联系人
      changeContact(value) {
        this.form.data.contact_name_str = `${value.contact_name}   ${value.phone}`
        this.form.data.contact_name = value.contact_name;
        this.form.data.contact_phone = value.phone;
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              order_id: this.info.id, //订单ID
              address_id: this.form.data.address_id, //食堂ID
              contact_name: this.form.data.contact_name, //联系人
              contact_phone: this.form.data.contact_phone, //联系电话
              merchant_account_id: this.form.data.merchant_account_id,//门店管理员ID
            }
            this.form.loading = true;
            this.$http.post("/admin/order/updateStore",_params).then(res => {
              if(res.code == 1) {
                this.$message.success('操作成功！')
                this.getDetail(this.info.id)
                Object.keys(this.form.data).forEach(key => (this.form.data[key] = '')); // 清空表单数据
                this.dialogFormVisible = false;
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      toWidow(url) {
        if (url) {
          window.open(url);
        }
      },
      handleClose() {
        Object.keys(this.form.data).forEach(key => (this.form.data[key] = '')); // 清空表单数据
        this.dialogFormVisible = false;
        this.$refs.elFormDom.resetFields()
      },
      handleClose2() {
        this.dialogVisibleEdit = false;
        this.$refs.elFormDom2.resetFields()
        this.goodsArr = [];
      },
      handleClose3() {
        this.dialogVisibleEdit = false;
        this.dialogVisibleEdit2 = false;
        this.goodsArr = [];
        this.formGoods.data.spec_id = '';
        this.formGoods.data.unit_name = '';
        this.formGoods.data.num = '';
        this.selindex = 0;
        this.$refs.elFormDomGoods.resetFields()
      },
      handleEdit() {
        this.dialogFormVisible = true;
      },
      // 退货
      handleRejected(row) {
        let dom = this.$refs.rejected;
        dom.toggle(true);
        dom.getDetail(row);
        dom = null;
      },
      // 修改采购商品
      handleGoods(row) {
        this.currRow = row;
        this.form2.data.goods_id = row.purchase_goods_id; // 上一次修改的商品反显出来
        this.form2.data.goods_name = row.purchase_name; // 上一次修改的商品反显出来
        this.formGoods.data.quantity = row.quantity; // 反显下单数量
        this.dialogVisibleEdit = true;
      },
      // 确认修改采购商品
      handleSubmit() {
        this.$refs.elFormDom2.validate(valid => {
          if(valid) {
            // 根据客户id和商品id，查询该客户是否已存在该商品
            this.$http.post('/admin/goods/list',{merchant_id:this.info.merchant_id,good_id: this.form2.data.goods_id}).then(res => {
              if(res.code == 1) {
                if(res.data.list.length > 0) {
                  this.selGoodsSpecArr = res.data.list[0].spec.filter(v => v.price != ""); // 过滤掉没有价格的规格
                  if(this.selGoodsSpecArr.length > 0) {
                    this.formGoods.data.spec_id = this.selGoodsSpecArr[0].id; // 默认选中第一个规格
                    this.formGoods.data.unit_name = this.selGoodsSpecArr[0].unit_name; // 默认第一个规格单位
                  } else {
                    this.formGoods.data.spec_id = '';
                    this.formGoods.data.unit_name = '';
                  }
                  this.$confirm(`该客户已存在商品【${this.form2.data.goods_name}】您是否需要帮助客户修改下单商品`,'',{
                    cancelButtonText: '只修改采购商品',
                    confirmButtonText: '修改下单商品',
                    center: true,
                  }).then(() =>{
                    this.dialogVisibleEdit2 = true;
                  }).catch(() => {
                    this.submitFun(); // 修改采购商品接口
                  })
                } else {
                  this.submitFun(); // 修改采购商品接口
                }
              }
            })
          }
        })
      },
      // 修改采购商品接口
      submitFun() {
        let _params = {
          id: this.info.id, //订单ID
          detail_id: this.currRow.id, //订单明细ID
          goods_id: this.form2.data.goods_id, //商品ID
        }
        this.form2.loading = true;
        this.$http.post('/admin/order/changeGoods',_params).then(res => {
          if(res.code == 1) {
            this.$message.success("操作成功")
            this.getDetail(this.info.id)
            this.handleClose2();
          }
        }).finally(() => {
          this.form2.loading = false;
        })
      },
      // 修改下单商品
      submitFun2() {
        this.$refs.elFormDomGoods.validate(valid => {
          if(valid) {
            let _params = {
              id: this.info.id, //订单ID
              detail_id: this.currRow.id, //订单明细ID
              spec_id: this.formGoods.data.spec_id, //规格ID
              num: this.formGoods.data.num, // 下单数量
            }
            this.formGoods.loading = true;
            this.$http.post('/admin/order/updateGoods',_params).then(res => {
              if(res.code == 1) {
                this.$message.success("操作成功")
                this.getDetail(this.info.id)
                this.handleClose3();
              }
            }).finally(() => {
              this.formGoods.loading = false;
            })
          }
        })
      },
      changeGoods(value) {
        this.form2.data.goods_id = value.id; // 商品id
        this.form2.data.goods_name = value.good_name; // 商品名称
      },
      clickSpec(item,index) {
        this.formGoods.data.spec_id = item.id; // 商品规格id
        this.formGoods.data.unit_name = item.unit_name; // 单位
        this.selindex = index;
      },
      // 计算价格
      countPrice() {
        const reg = /^[\d]+$/;
        // 任何单位的数量都可以输入5位小数
        if (this.formGoods.data.num && !reg.test(this.formGoods.data.num)) {
          this.formGoods.data.num = this.formGoods.data.num.match(/\d+(\.\d{0,5})?/) ? this.formGoods.data.num.match(/\d+(\.\d{0,5})?/)[0] : '';
        }
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.searchForm.data.goods_name = ''; // 清空搜索条件
        this.detailArr = [];
        this.tableData = [];
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.spec {
  height: 28px;
  line-height: 28px;
  border: solid 1px #eee;
  border-radius: 3px;
  padding: 0 10px;
  margin-bottom: 10px;
  cursor: pointer;
  flex:1;
}
.spec:hover {
  border: solid 1px #2cb167;
}
.active {
  border: solid 1px #2cb167;
  background: #eaf7f0;
}
</style>
