<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form
        ref="elFormDomSel"
        inline
        :model="table.params"
        :size="subUnitSize"
      >
        <el-form-item label="配送日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select
            v-model="table.params.address_id"
            filterable
            placeholder="请输入"
            clearable
            @focus="getStoreSel"
            @change="onSearch"
          >
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px"
                >({{ item.merchant_name }})</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display: flex; align-items: center">
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="adddialogVisible"
          >添加运费</el-button
        >
      </div>
    </div>

    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :tree-props="{ children: 'children', hasChildren: 'has_child' }"
      :hasPagionation="true"
      :default-expand-all="true"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:amount="{ row }">
        <el-link type="primary" @click="handlesupplier(row)">{{
          row.amount
        }}</el-link>
      </template>
      <template v-slot:purchase_count="{ row }">
        <el-link type="primary" @click="handlesupdate(row)">{{
          row.purchase_count
        }}</el-link>
      </template>
      <template v-slot:action="{ row }">
        <el-button type="text" @click="remove(row)" class="delBtn"
          >删除</el-button
        >
      </template>
    </VTable>

    <!--  -->
    <el-dialog
      title="添加运费"
      :visible.sync="dialogVisible"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-form
        ref="dynamicValidateForm"
        :model="addform"
        :size="subUnitSize"
        label-width="100px"
      >
        <el-form-item
          label="交货日期"
          prop="date"
          :rules="[
            { required: true, message: '请选择交货日期', trigger: 'change' },
          ]"
        >
          <el-date-picker
            style="width: 220px"
            v-model="addform.date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="请选择交货日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="关联采购单"
          :rules="[
            { required: true, message: '请选择关联采购单', trigger: 'change' },
          ]"
          prop="purchase_ids"
        >
          <checkboxs
            ref="checkboxs"
            :date="addform.date"
            v-model="addform.purchase_ids"
          ></checkboxs>
        </el-form-item>
        <el-form-item
          label="运费总额"
          prop="store"
          :rules="[
            { required: true, message: '请选择运费', trigger: 'change' },
          ]"
        >
          <selectinput v-model="addform.store" ref="selectinput"></selectinput>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" :loading="loading">取 消</el-button>
        <el-button type="primary" @click="add" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="修改运费"
      :visible.sync="supplierVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-form :model="supplier" :size="subUnitSize" label-width="100px">
        <el-form-item
          label="运费总额"
          prop="store"
          :rules="[
            { required: true, message: '请选择运费', trigger: 'change' },
          ]"
        >
          <selectinput
            v-model="supplier.store"
            :storelist="supplier.store"
          ></selectinput>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="supplierVisible = false">取 消</el-button>
        <el-button type="primary" @click="freightupdate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="关联采购单"
      :visible.sync="updatePurchaseVisible"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-form
        ref="updateValidateForm"
        :model="updateform"
        :size="subUnitSize"
        label-width="100px"
      >
        <el-form-item
          label="关联采购单"
          :rules="[
            { required: true, message: '请选择关联采购单', trigger: 'change' },
          ]"
          prop="purchase_ids"
        >
          <checkboxs
            ref="checkboxs1"
            :date="updateform.date"
            v-model="updateform.purchase_ids"
            :purchase_idarr="updateform.purchase_idarr"
          ></checkboxs>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close1">取 消</el-button>
        <el-button type="primary" @click="updatePurchase">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import checkboxs from "./components/checkboxs";
import selectinput from "./components/selectinput.vue";
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
export default {
  name: "SupplierIndex",
  components: {
    VTable,
    checkboxs,
    selectinput,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "date", label: "交货日期", hidden: false },
        {
          name: "amount",
          label: "运费总额",
          showTooltip: true,
          hidden: false,
        },
        {
          name: "purchase_count",
          label: "关联采购单数",
          hidden: false,
        },
        { name: "create_name", label: "创建人", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        {
          name: "action",
          label: "操作",
          fixed: "right",
          hidden: false,
        },
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          date: "", // 配送日期
          address_id: "",
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
      },
      storeArr: [], // 食堂
      dialogVisible: false,
      addform: {
        date: "",
        purchase_ids: "",
        store: [
          {
            store_id: "",
            amount: "", //运费
          },
        ],
      },
      supplierVisible: false,
      supplier: {
        id: "",
        store: [
          {
            store_id: "",
            amount: "", //运费
          },
        ],
      },
      updatePurchaseVisible: false,
      updateform: {
        id: "",
        purchase_ids: "",
        date: "",
        purchase_idarr: [],
      },
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    // 重置查询条件
    reset() {
      this.$refs.elFormDomSel.resetFields();
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.getTable();
    },
    getTable() {
      let _params = { ...this.table.params };
      this.table.loading = true;
      this.$http.get("/admin/freight/list", { params: _params }).then((res) => {
        if (res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      });
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        { id: row.id },
        "/admin/freight/delete"
      );
    },
    adddialogVisible() {
      this.dialogVisible = true;
      this.$refs.dynamicValidateForm &&
        this.$refs.dynamicValidateForm.resetFields();
    },
    close() {
      this.$refs.dynamicValidateForm.resetFields();
      this.addform = {
        date: "",
        purchase_ids: "",
        store: [
          {
            store_id: "",
            amount: "", //运费
          },
        ],
      };
      this.$refs.checkboxs.reset();
      this.$refs.selectinput.reset();
      this.dialogVisible = false;
    },
    close1() {
      this.updateform = {
        id: "",
        purchase_ids: "",
        date: "",
        purchase_idarr: [],
      };
      this.$refs.checkboxs1.reset();
      this.updatePurchaseVisible = false;
    },
    add() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let addform = { ...this.addform };
          if (addform.store.some((item) => !item.amount || !item.store_id)) {
            this.$message.error("请选择运费总额");
            return false;
          }
          this.$http
            .post("/admin/freight/add", addform)
            .then((res) => {
              this.loading = false;
              if (res.code === 1) {
                this.close();
                this.$message.success("添加成功");
                this.onSearch();
                this.close();
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    freightupdate() {
      if (this.supplier.store.some((item) => !item.amount || !item.store_id)) {
        this.$message.error("请选择运费总额");
        return false;
      }
      this.supplierVisible = false;
      this.$http
        .post("/admin/freight/update", { ...this.supplier })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success("修改成功");
            this.onSearch();
          }
        });
    },
    // 修改供应商
    handlesupplier(row) {
      this.supplier = {
        id: row.id,
        store: row.details.map((item) => {
          return {
            store_id: item.store_id,
            amount: item.amount,
          };
        }),
      };
      this.supplierVisible = true;
    },
    //
    handlesupdate(row) {
      this.updateform = {
        id: row.id,
        purchase_ids: row.purchase_ids,
        date: row.date,
        purchase_idarr: row.purchase_ids.split(","),
      };
      this.updatePurchaseVisible = true;
      this.$nextTick(() => {
        this.$refs.checkboxs1.setCheckedKeys(row.date);
      });
    },
    updatePurchase() {
      this.$refs.updateValidateForm.validate((valid) => {
        if (valid) {
          this.updatePurchaseVisible = false;
          this.$http
            .post("/admin/freight/updatePurchase", { ...this.updateform })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success("修改成功");
                this.onSearch();
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
