<template>
  <el-dialog
    append-to-body
    width="560px"
    title="出库"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    >
    <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='80px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-descriptions title="" :column="2">
          <el-descriptions-item label="标准名称">{{curRow.norm_name}}</el-descriptions-item>
          <el-descriptions-item label="下单分类">{{curRow.cate_name}}</el-descriptions-item>
          <el-descriptions-item label="单位">{{curRow.unit_name}}</el-descriptions-item>
          <el-descriptions-item label="规格">
            <span v-if="curRow.spec_names.length > 1">存在多个商品规格</span>
            <span v-else>{{curRow.spec_names[0]}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="现有库存">
            <!-- 现有库存 = (入库数量 - 已出库 - 已处理) -->
              <span>{{(Number(curRow.quantity)*100000 - Number(curRow.out_quantity)*100000 - Number(curRow.env_quantity)*100000 )/100000}} {{curRow.unit_name}}</span>
          </el-descriptions-item>
        </el-descriptions>
        <el-row>
          <el-col :span="24">
            <el-form-item label="出库数量" prop="quantity">
              <el-input
                v-model.trim="form.data.quantity"
                placeholder="请输入"
                style="width:180px;"
                clearable @input="form.data.quantity=form.data.quantity.match(/\d+(\.\d{0,5})?/) ? form.data.quantity.match(/\d+(\.\d{0,5})?/)[0] : ''">
                <span slot="suffix">{{curRow.unit_name}}</span>
              </el-input>
              <el-radio-group v-model="form.data.type" style="margin-left:10px;">
                <el-radio :label="1">正常出库</el-radio>
                <el-radio :label="2">环保处理</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="出至食堂" prop="address_id" v-if="form.data.type == 1">
          <el-select v-model="form.data.address_id" filterable placeholder="请选择" @focus="getStore" clearable style="width:100%;">
            <el-option v-for="item in storeArr" :label="item.name"  :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传凭证" prop="voucher" v-if="form.data.type == 2">
          <el-upload
            action="/api/common/qiniu/upload"
            :limit="1"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            class="imgWrap"
            style="width: 148px; height: 148px;overflow:hidden;"
            accept="image/jpeg, image/png, image/gif"
          >
            <img v-if="form.data.voucher" :src="form.data.voucher" style="object-fit: cover;width:100%;" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        
      </el-form>
    
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click='confirm' :loading="loading">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'StoreStockExcise',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        curRow: {
          spec_names: [],
        },
        storeArr: [], // 食堂
        form: {
          loading: false,
          data: {
            id: '',
            type: 1, //类型 1:正常出库 2:环保处理
            quantity: '',
            address_id: '',
            voucher: '',
          },
          rules: {
            quantity: [{ required: true, message:'请输入', trigger: 'blur'}],
            address_id: [{ required: true, message:'请选择', trigger: 'change'}],
            voucher: [{ required: true, message:'请上传', trigger: 'change'}],
          }
        },
        fileList: [],
        dialogImageUrl: '',
        dialogVisibleUrl: false,
       
      }
    },
    methods: {
      getDetail(row) {
        this.curRow = row;
      },
      // 客户的食堂列表
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false;
        this.$refs.elFormDom.resetFields()
        this.$emit('refresh')
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        this.form.data.voucher = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate();
      },
      handleRemove(file, fileList) {
        this.fileList = [];
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传图片大小不能超过 20MB!');
        }
        return isLt20M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              ids: this.curRow.ids,
              type: this.form.data.type, //类型 1:正常出库 2:环保处理
              quantity: this.form.data.quantity, // 出库、环保数量
              address_id: this.form.data.type == 1 ? this.form.data.address_id : '', // 食堂id
              voucher: this.form.data.type == 2 ? this.form.data.voucher : '', // 凭证
            };
            console.log(_params)
            this.loading = true;
            this.$http.post('/admin/inventory/operation', _params).then(res => {
              if(res.code === 1) {
                this.$message.success("操作成功！")
                this.toggle(false)
              }
            }).finally(() => {
              this.loading = false;
            })
          }
        })
      },
    }
  }
</script>
<style scoped>
.item-row {
  margin-top: 5px;
}
</style>
