<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image
            class="back-img"
            :src="require('@/assets/img/back.png')"
          ></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          详情
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <el-divider content-position="left">退货信息</el-divider>
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item
            v-for="(item, index) in this.detailArr"
            :key="index"
            :labelStyle="labelStyle"
            :label="item.title"
            >{{ item.value }}</el-descriptions-item
          >
          <el-descriptions-item
            label="处理时间"
            v-if="info.refund_status != 1"
            >{{ info.deal_refund_time }}</el-descriptions-item
          >
          <el-descriptions-item label="处理人" v-if="info.refund_status != 1">{{
            info.handle_name
          }}</el-descriptions-item>
          <el-descriptions-item label="问题描述">{{
            info.refund_desc
          }}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">照片凭证</el-divider>
        <el-image
          v-for="(img, index) in imgList"
          :key="index"
          style="width: 100px; height: 100px; margin: 5px"
          :src="img"
          fit="cover"
          :preview-src-list="imgList"
        ></el-image>

        <el-divider content-position="left">商品信息</el-divider>
        <el-descriptions title="" direction="vertical" :column="10" border>
          <el-descriptions-item label="商品ID">
            {{ info.good_id }}
          </el-descriptions-item>
          <el-descriptions-item label="商品图片">
            <el-image
              v-if="!!info.good_picture"
              style="width: 60px; height: 60px"
              :src="info.good_picture"
              :preview-src-list="[info.good_picture]"
            >
            </el-image>
            <el-image
              v-else
              style="width: 40px; height: 30px"
              :src="require('@/assets/img/logo-icon.jpeg')"
              fit="cover"
              :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"
            ></el-image>
          </el-descriptions-item>
          <el-descriptions-item label="商品简称">{{
            info.good_name
          }}</el-descriptions-item>
          <el-descriptions-item label="所属类目">{{
            info.cate_name
          }}</el-descriptions-item>
          <el-descriptions-item label="所属供应商">{{
            info.merchant_name
          }}</el-descriptions-item>
          <el-descriptions-item label="售价/元"
            >{{ info.price }}/{{
              info.quantity_unit_name
            }}</el-descriptions-item
          >
          <el-descriptions-item label="商品规格">{{
            info.spec_name
          }}</el-descriptions-item>
          <el-descriptions-item label="数量/件">
            <div>x{{ info.quantity }}</div>
            <div v-if="info.refund_quantity != 0">
              已退:{{ info.refund_quantity }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="金额">
            <div>x{{ info.amount }}</div>
            <div v-if="info.refund_amount != 0">
              已退:{{ info.refund_amount }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="备注">{{
            info.remark
          }}</el-descriptions-item>
        </el-descriptions>
        <!-- <el-table
          :data="tableData"
          border
          style="width: 100%">
          <el-table-column
            prop="date"
            label="商品ID"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="商品分类"
            width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品名称">
          </el-table-column>
          <el-table-column
            prop="address"
            label="保质期/天">
          </el-table-column>
          <el-table-column
            prop="address"
            label="售价/元">
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品规格">
          </el-table-column>
          <el-table-column
            prop="address"
            label="数量/件">
          </el-table-column>
          <el-table-column
            prop="address"
            label="金额/元">
          </el-table-column>
        </el-table> -->

        <el-divider content-position="left">收货信息</el-divider>
        <el-descriptions class="margin-top" title="" :column="3" border>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">
              <i class="el-icon-user"></i>
              用户名
            </template>
            {{ info.shipping_info.name }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{ info.shipping_info.phone }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              联系地址
            </template>
            {{ info.shipping_info.address }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinTable } from "@/mixins/table.js";
export default {
  name: "PlatformDetail",
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      isChange: false,
      info: {
        shipping_info: {},
      },
      detailArr: [],
      imgList: [],
      img_value: [],
      labelStyle: { width: "180px" },
      tableData: [],
    };
  },
  methods: {
    getDetail(row) {
      this.$http
        .post("/admin/order/refundDetail", { id: row.id })
        .then((res) => {
          if (res.code == 1) {
            this.info = res.data; // 商品信息
            this.detailArr = [
              { title: "订单编号", value: res.data.order_no, type: 1 },
              { title: "客户名称", value: res.data.merchant_name, type: 1 },
              { title: "下单时间", value: res.data.create_time, type: 1 },
              { title: "下单账号", value: res.data.merchant_account, type: 1 },
              { title: "申请退货时间", value: res.data.refund_time, type: 1 },
              { title: "退货数量", value: res.data.refund_quantity, type: 1 },
              {
                title: "退货理由",
                value: this.toStr(
                  { 1: "质量问题", 2: "发错货", 3: "漏发少发" },
                  res.data.refund_type
                ),
                type: 1,
              },
              { title: "对应金额", value: `￥${res.data.amount}`, type: 1 },
              {
                title: "状态",
                value: this.toStr(
                  { 1: "申请中", 2: "同意", 3: "已拒绝" },
                  res.data.refund_status
                ),
                type: 1,
              },
            ];
            this.imgList = res.data.refund_pictures;
          }
        });
    },
    reset(done) {
      this.isChange = false;
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 返回
    handleReturn() {
      this.$emit("refresh");
      this.toggle(false);
      this.info = {
        shipping_info: {},
      };
      this.detailArr = [];
    },
  },
};
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}

.imgLabel {
  font-size: 14px;
  color: #303133;
  margin: 15px 0;
  display: inline-block;
}
.img {
  width: 120px;
  height: 120px;
}
</style>
